import React from 'react';
import PieDePagina from '../footer/Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import NavigationBar from '../nav/Nav.js';
import 'jquery/dist/jquery.min.js';
import '../commoncss/animate.min.css';
import '../../fonts/css/open-iconic.css';
import './fueradeservicio.css';
import '../commoncss/fonts.css';

class FueraDeServicio extends React.Component {

	constructor(props)
	{
		super(props);
		this.state = {
			navmode :
			{
				modo : 0,
				nombre : '',
				apepat : '',
				apemat : '',
				dev : 1, 		// Marcas de tamaño
				oos : 1			// Fuera de servicio - out of service
			}
		}
	}

	render()
	{

		let { navmode } = this.state;

		return (
			<div className="principal">
	
				{/* MENU SUPERIOR  */}
				<NavigationBar {...navmode} />

				{/* MENSAJE CENTRAL */}
				<div id="inicio" className="inicio fondomensaje container-fluid">
					<div className="row h-100 justify-content-center animated rubberBand">
						<div className="formMensaje col-11 col-md-10 col-lg-9 col-xl-8 mensaje align-self-center sombra">

							<p className="kau bl mensajealign textshadow xxl pt-1">
								Sistema de Ingreso&nbsp;2021 C.B.T.i.s.&nbsp;#41
							</p>

							<p className="lat bl mensajealign textshadow justificadomini md pt-3">
								<br/>LA PUBLICACION DE RESULTADOS<br/>
								SERA A PARTIR DEL <br/><br/>
								<span className="am b">31 DE AGOSTO</span><br/><br/>
								{/* REMOVER COMENTARIO CUANDO LA PAGINA SE ESTE UTILIZANDO
									NO OLVIDES REGRESAR
									<span className="am"> <b> ANTES DEL 5 DE AGOSTO </b> </span>
									SI AUN NO HAS DESCARGADO E IMPRESO TUS MANUALES PARA EL CURSO DE INDUCCIÓN.
								*/}
						
							</p>

						</div>
					</div>
				</div>

				{/* PIE DE PAGINA */}
				<div className="footershadow">
					<PieDePagina />
				</div>
			</div>
		);
	}
}

export default FueraDeServicio;