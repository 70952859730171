import React from 'react';
import {Row, Col} from 'reactstrap';
import {request} from './commonjs/request.js';
import NavigationBar from './nav/Nav.js';
import Descargas from './descargas/Descargas.js';
import PieDePagina from './footer/Footer.js';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './principal.css';
import './commoncss/animate.min.css';
import './commoncss/fonts.css';
import comunicado from './../images/comunicado.jpeg';
import aviso from './../images/aviso.jpg';
//import video from './video.mp4';

import FichasXNumero from '../docs/datospornumero.json';
import FichasXGrupo from '../docs/datosporgrupo.json';

//Función para desplegar listas

const FichasPorNumero = ({fichaspornumero}) =>
(
	<div>
		<table className="table table-striped table-sm sm">
			<thead>
				<tr>
					<th width='30%'><center>FICHA</center></th>
					<th width='30%'><center>GRUPO</center></th>
					<th width='40%'><center>TURNO</center></th>
				</tr>
			</thead>
			<tbody>
			{
				(typeof(fichaspornumero))!=='undefined'?
					fichaspornumero.fichas.map((item, i) =>
						<tr key = { i } >
							<td width='30%' className="text-center"> <b> { item[0]} </b></td>
							<td width='35%' className="text-center"> { item[1] } </td>
							<td width='45%' className="text-center"> { item[2] } </td>
						</tr>
					)
				:
					<tr>
						<td width='30%' className="text-center"> <b> - - - - - </b> </td>
						<td width='35%' className="text-center"> - - - - - </td>
						<td width='45%' className="text-center"> - - - - - </td>
					</tr>
			}
			</tbody>
		</table>
	</div>
)


//Función para desplegar listas

const FichasPorGrupo = ({fichasporgrupo}) =>
(

	<div className="row">
	{
		fichasporgrupo.map((item,i) =>
			<div key = { i } className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 pt-3 pb-1 pb-md-3 pb-lg-4">
				<div className="card sombra">
					<div className="card-header"> <b> GRUPO "{item.grupo}" {item.turno} </b> </div>
					<div className="card-body sm">
						<table className="table table-striped table-sm">
							<tbody>
							{
								item.fichas.map((item2,j) => <tr key = {j}><td><b>{j+1} - {item2}</b></td></tr>)
							}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
	</div>
)

class Principal extends React.Component
{
	constructor(props){
		super(props);

		this.state={
			usuario :
			{
				ficha : '',
				apellido_paterno : '',
				fecha : '',
			},
			encontrado : 2,
			navmode : { 
				modo : 1,
				dev : 1,
				oos : 0
			},
			fichaspornumero : [],
			fichasporgrupo : []
		}
	}

	componentWillMount()
	{

		// Swal.fire({
		// 	html: '<b><br> Para sacar tu ficha de nuevo ingreso entra a: <br><br>'+
		// 		  '<a href="http://fichas.cbtis41.net">http://fichas.cbtis41.net</a></b><br><br>',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

		// Swal.fire({
		// 	html: '<b><br> Revisa si tu número de ficha aparece en la<br><br>'+
		// 		  '<a href="#resultados">LISTA DE SELECCIONADOS</a></b><br><br> '+
		// 		  '<b>Si es así, ingresa a esta página a partir del día<br><br>'+
		// 		  '<spam class="am" >LUNES 24 DE JULIO</spam><br><br>'+
		// 		  'para revisar la información que se te solicitará, y que pasos debes seguir para comenzar tu proceso de inscripción. <br><br><br>'+
		// 		  '<h3><b> <spam class="am">F E L I C I D A D E S ! ! !</spam> </b></h3><br>',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

		// Swal.fire({
		// 	html: '<b><br> Para inscribirte debes entrar a:</b><br><br> '+
		// 		  '<b> <a href="http://reinscripciones.cbtis41.net">http://reinscripciones.cbtis41.net</a></b>'+
		// 		  '<br><br>',
		// 	focusConfirm: false,
		// 	showConfirmButton: false,
		// });

		// Swal.fire({
		// 	width: '450px',
		// 	html: ' <img src="'+ aviso +'" width="400px">',
		// 	footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
		// });

		// Swal.fire({
		// 	html:
		// 		'<b>Escuha el mensaje que tenemos para tí!</b><br/> <br>' +
		// 		'<video width="200" height="350" controls autoplay> '+
		// 			'<source src='+video+' type="video/mp4">'+
		// 		'</video> ',
		// 	imageHeight: 500
		// })

		// Swal.fire({
		// 	html: '<br> A partir del <b> 1ro. de febrero,</b> <br>'+
		// 		  '<b>no podras utilizar tu número de ficha</b> <br>'+
		// 		  'para revisar tu información escolar.<br><br>'+
		// 		  '<b>Consulta</b> y anota tu número de control en tu <b>historial académico</b>, '+
		// 		  'ya que será el que utilizaras para cualquier trámite que desees realizar.',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

		var {fichasporgrupo, fichaspornumero} = this.state;	
		fichaspornumero = FichasXNumero;
		fichasporgrupo = FichasXGrupo;

		this.setState({fichaspornumero : fichaspornumero, fichasporgrupo : fichasporgrupo});		
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var {usuario, encontrado} = this.state;

		usuario[name] = value;
		encontrado=2;

		this.setState({ usuario : usuario, encontrado : encontrado });
	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var {usuario, encontrado} = this.state;
		let temp = this;

		request.get('/buscar/'+usuario.ficha+'/'+usuario.apellido_paterno+'/'+usuario.fecha)
		.then(function(response)
		{
			if(response.status === 200)
			{
				usuario = response.data;
				if(usuario.apellido_paterno != null)
				{
					encontrado = 1
					temp.setState({ encontrado : encontrado });
				}
				else
				{
		 			encontrado = 0;
		 			temp.setState({ encontrado : encontrado });
				}
			}
		})
		.catch(error => { });

		// Llamada para acceso rapido, solo con número de ficha

		// request.get('/buscarsp/'+usuario.ficha) 
		// .then(function(response)
		// {
		// 	if(response.status === 200)
		// 	{
		// 		usuario = response.data;
		// 		if(usuario.apellido_paterno != null)
		// 		{
		// 			encontrado = 1
		// 			temp.setState({ encontrado : encontrado });
		// 		}
		// 		else
		// 		{
		// 			encontrado = 0;
		// 			temp.setState({ encontrado : encontrado });
		// 		}
		// 	}
		// })
		// .catch(error => { });

	}

	render(){

		// Para uso regular
		//let { usuario, encontrado } = this.state;
		// Para publicación de resultados
		 
		let { usuario, encontrado, fichaspornumero, fichasporgrupo, navmode } = this.state;
		//let { usuario, encontrado, navmode } = this.state;

		if(encontrado === 1) return <Descargas ficha={usuario.ficha} />;

		return (
			<div className="principal">
	
				{/* MENU SUPERIOR  */}
				<NavigationBar {...navmode} />


				{/* TITULO */}

				<div id="inicio" className="inicio container-fluid">
					
					<div className="row pt-5 pb-5 justify-content-center">
						<div className=" col-12 col-sm-11 col-md-10 pt-5">
							<div className="row h-100 justify-content-around">
								<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra">
									<p className="kau bl textshadow xxl am cen pt-2">
										Sistema de Registro&nbsp;-&nbsp;C.B.T.i.s.&nbsp;#41
									</p>
								</div>
							</div>
						</div>
					</div>

					{/* FORMULARIO DE LOGIN */}

					<div id="inicio" className="inicio container-fluid">
						
						<div className="row pt-5 pb-5 justify-content-center">
					
							<div className=" col-12 col-sm-11 col-md-10 col-lg-5 col-xl-6 pt-4 pb-5">
								<div className="row h-100 justify-content-around">
									<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra">
										<p className="kau bl tituloalign textshadow xxl">
											Registro para alumnos de nuevo ingreso <span className="sm"> (Sel. 2024)</span>
										</p>
										<p className="lat bl tituloalign textshadow justificadomini sm">
											Si tu número de ficha aparece en la
											<a className="res b" href="#resultados"> LISTA DE SELECCIONADOS</a>
											, ingresa a la página con los datos que se solicitan.
										</p>
									</div>
								</div>
							</div>
							<div className="col-0 col-lg-1">
							</div>
							<div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
								<form className="container-fluid loginForm animated bounceInUp sombra" onSubmit={this.handleFormSubmit}>
									<Row className="pt-4">
										<Col> <label> <span className="oi am" data-glyph="pencil" aria-hidden="true"></span> Número de ficha: </label> </Col>
									</Row>
									<Row className="pb-4">
										<Col> <input className="form-control f" type="text" value={usuario.ficha} required name="ficha" placeholder="####" onChange={this.handleInputChange} /> </Col>
									</Row>
								
									<Row>
										<Col> <label> <span className="oi am" data-glyph="person" aria-hidden="true"></span> Apellido Paterno: </label> </Col>
									</Row>
									<Row className="pb-4">
										<Col> <input className="form-control f" type="text" value={usuario.apellido_paterno} required name="apellido_paterno" onChange={this.handleInputChange} /> </Col>
									</Row>
									<Row>
										<Col> <label> <span className="oi am" data-glyph="calendar" aria-hidden="true"></span> Fecha de nacimiento: </label> </Col>
									</Row>
									<Row className="pb-4">
										<Col> <input className="form-control f" type="date" value={usuario.fecha} required name="fecha" onChange={this.handleInputChange} /> </Col>
									</Row>
								
									<Row className="pt-2">
										<Col> <button className="btn btn-warning btn-block" type="submit"> <b> Ingresar </b> &nbsp; <span className="oi" data-glyph="account-login" aria-hidden="true"> </span> </button> </Col>
									</Row>
									<Row className="pt-3 pb-3">
										<Col>
											{
												encontrado === 0 ? 
												//<div className="am"> <span className="oi" data-glyph="warning" aria-hidden="true"> </span> <font className="bl"> <i> El proceso de inscripción aun no inicia </i> </font> </div> :
												<div className="am"> <span className="oi" data-glyph="warning" aria-hidden="true"> </span> <font className="bl"> <i> No coinciden los datos </i> </font> </div> :
												<div className="negra"> . </div>
											}
										</Col>
									</Row>
								</form>
							</div>

						</div>
					
					</div>

					{/* MENSAJE INFORMATIVO EN LUGAR DE MENSAJE DE LOGIN */}

					{/*<div className="row pt-5 pb-5 justify-content-center">
					<div className=" col-12 col-sm-11 col-md-10">
							<div className="row h-100 justify-content-around">
								<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra pt-3">
									<p className="latr bl md cen">
										<br/>
										Si quieres saber si fuiste seleccionado revisa la&nbsp;
										<a href="#resultados" className="am b">lista de seleccionados</a>.
										<br/><br/>
										El periodo de inscripción será en línea, del
										<span className="am b"> 5 al 30 de Julio </span>
										 y concluirá en agosto de manera presencial con la entrega de tus documentos. 
										<br/><br/>
										Revisa los&nbsp;
										<a href="#requisitos" className="am b">requisitos de inscripción</a>&nbsp;
										para que estes preparado, porque si no te inscribes en este periodo,&nbsp;
										<span className="am b">PIERDES TU LUGAR</span>.
										<br/><br/>
									</p>
								</div>
							</div>
						</div>
					</div>*/}

					{/*<div id="requisitos" className="row pt-5 pb-5 justify-content-center">
						<div className="col-12 col-sm-11 col-md-10">
							<div className="row h-100 justify-content-around">
								<div className="formTitulo col-11 col-sm-11 col-md-10 sombra pt-3">
									<p className="latr bl md">
										<br/>
										<span className="am b">REQUISITOS PARA INSCRIPCIÓN</span><br/>
										<br/>
										<span className="am"> - </span> Acta de nacimiento<br/>
										<span className="am"> - </span> Certificado de secundaria ó constancia de terminación de estudios<br/>
										<span className="am"> - </span> Constancia de Clave Única de Registro de Población (CURP)<br/>
										<span className="am"> - </span> Certificado médico<br/>
										<span className="am"> - </span> 6 fotografías tamaño infantil, blanco y negro en papel mate (Recientes)<br/>
										<span className="am"> - </span> Cartilla de vacunación<br/>
										<span className="am"> - </span> Comprobante de asignación de Número de Seguridad Social (NSS)<span className="am">*</span><br/><br/>
										El proceso de
										<span className="am"> PRE-REGISTRO EN LINEA </span>
										será los días
										<span className="am"> 27 al 29 de julio</span>
										.
										<br/>El
										<span className="am"> PROCESO DE INSCRIPCIÓN </span>
										y
										<span className="am"> ENTREGA DE DOCUMENTOS </span>
										en el plantel, será los días 
										<span className="am"> 1, 2 y 3 de agosto</span>
										, de acuerdo al calendario por grupos que se publicará el día 27 de julio.
										<br/><br/>
										En caso de
										<span className="am"> NO ASISTIR </span>
										el
										<span className="am"> ASPIRANTE </span>
										acompañado de su 
										<span className="am"> PADRE O TUTOR </span>
										el día y hora indicados, 
										<span className="am"> PERDERÁ SU LUGAR. </span>
										<br/><br/>
										
										<span className="sm">
											<span className="am">*</span>
											Para obtener tu comprobante de asignación de Número de Seguridad Social entra a la siguiente
											<a className="res b" href="https://serviciosdigitales.imss.gob.mx"> LIGA</a>
											, en caso de no poder descargarlo deberás acudir a la clínica 32 a realizar el trámite de manera presencial.
										</span>
										<br/><br/>
									</p>
								</div>
							</div>
						</div>
					</div>*/}

{/*					<div className="row pt-5 pb-2 justify-content-center">
						<div className=" col-12 col-sm-11 col-md-10">
							<div className="row h-100 justify-content-around">
								<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra pt-3">
									<p className="latr bl md cen">
									
										<img className="foto" src={comunicado}/>
										<br/>
									</p>
								</div>
							</div>
						</div>
					</div>*/}

					{/*<div className="row pt-5 pb-2 justify-content-center">
						<div className=" col-12 col-sm-11 col-md-10">
							<div className="row h-100 justify-content-around">
								<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra pt-3">
									<p className="latr bl md cen">					
										<br/>
										<span className="am b">¡AVISO IMPORTANTE!</span><br/><br/>
										Si 
										<span className="am b"> no apareces </span>
										en la lista de los aspirantes seleccionados, 
										nuestra escuela hermana el
										<span className="am b"> CETis 74 </span>
										tiene un lugar para ti.<br/><br/>
										Para asegurar tu lugar, lo único que tienes que hacer es acudir a sus instalaciones 
										localizadas en el ejido Chapultepec, con los siguientes documentos:<br/><br/>
										- Tu ficha de aspirante del CBTis 41.<br/>
										- Copia de acta de nacimiento.<br/>
										- Copia de CURP.<br/>
										- Copia de Certificado de Secundaria.<br/><br/>
										<span className="am b">
											ATENCIÓN DE LUNES A VIERNES DE 9:00 A 12:00 hrs.
										</span>
										<br/><br/>
									</p>
								</div>
							</div>
						</div>
					</div>*/}

					<div className="row espacio"> </div>
				</div>


				{/* LISTAS DE RESULTADOS */} 

			{/*	<div id="resultados" className="resultados container-fluid">
					<Row className="pb-5">
						<Col className="col-12">
							<h1> <p className="kau lg textshadowmd"> Listado de fichas de aspirantes seleccionados </p> </h1>
							<p className="textshadowsm i"> Puedes revisar los resultados por número de ficha o por grupo. </p>
						</Col>
					</Row>
					<Row className="pt-1 pb-4">
						<Col>

							<ul className="nav nav-tabs">
								<li className="nav-item">
									<a className="nav-link pestania sm active" data-toggle="tab" href="#pornumero"> 
										<span className="oi" data-glyph="person" aria-hidden="true"> </span>
										&nbsp;<font className="b">Por número de ficha</font>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link pestania sm" data-toggle="tab" href="#porgrupo">
										<span className="oi" data-glyph="people" aria-hidden="true"> </span>
										&nbsp;<font className="b">Por grupo</font>
									</a>
								</li>
							</ul>

								<div className="tab-content">
							*/}
		
								{/* RESULTADOS POR NUMERO */}
								
							{/*	<div id="pornumero" className="tab-pane fade-in active in">
									<div className="container-fluid pt-4 pb-3 pb-md-4 pb-lg-5">
										<div className="row justify-content-around">
											<div className="col-12 col-sm-6 container-fluid">
												<div className="row">
													<div className="col-md-12 col-xl-6 pt-4">
														<div className="card-body sombra redondo">
															<FichasPorNumero fichaspornumero = {fichaspornumero[0]} />
														</div>
													</div>
													<div className="col-md-12 col-xl-6 pt-4">
														<div className="card-body sombra redondo">
															<FichasPorNumero fichaspornumero = {fichaspornumero[1]} />
														</div>
													</div>										
												</div>
											</div>
											<div className="col-12 col-sm-6 container-fluid">
												<div className="row">
													<div className="col-md-12 col-xl-6 pt-4">
														<div className="card-body sombra redondo">
															<FichasPorNumero fichaspornumero = {fichaspornumero[2]} />
														</div>
													</div>
													<div className="col-md-12 col-xl-6 pt-4">
														<div className="card-body sombra redondo">
															<FichasPorNumero fichaspornumero = {fichaspornumero[3]} />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>*/}
		
								{/* RESULTADOS POR GRUPO 
								
									<div id="porgrupo" className="tab-pane fade-in">
									<div className="container-fluid pt-4 pb-4">
										<FichasPorGrupo fichasporgrupo={fichasporgrupo} />
									</div>
								</div>

							</div>

						</Col>
					</Row>
				</div> */} 
  

				{/* SEGUNDA IMAGEN DE FONDO */}
{
				// <div id="fondo2" className="fondo2 container-fluid">
				// </div>
}
				{/* PIE DE PAGINA */}
				<PieDePagina />
			</div>
		);
	}
}

export default Principal;