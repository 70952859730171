import React from 'react';
import Descargas from '../descargas/Descargas.js';
import PieDePagina from '../footer/Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../../images/logo1.png';
import './test.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';

class Resolvertest extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state={
			usuario :
			{
				ficha : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				fecha : '',
				referencia : '',
			},
			respuesta :
			{
				n01 : '',
				n02 : '',
			},
			imprimir : false,
			salir : false,
		}
	}

	componentDidMount()
	{
		var {usuario} = this.state;

		usuario.ficha = this.props.ficha;
		usuario.nombre = this.props.nombre;
		usuario.apellido_paterno = this.props.apellido_paterno;
		usuario.apellido_materno = this.props.apellido_materno;
		usuario.fecha = this.props.fecha;
		usuario.referencia = this.props.referencia;

		this.setState({ usuario : usuario });	
	}

	imprimir=()=>
	{

	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var { imprimir } = this.state;
		imprimir=true;
		this.setState({imprimir : imprimir});
	}

	salir=()=>
	{
		var {salir} = this.state;
		salir=true;
		this.setState({salir : salir});
	}

	render()
	{
		let { salir, imprimir, usuario } = this.state;

		if(salir) return <Descargas {...usuario} />;

		return (
			<div className="principal">

				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-2 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					&nbsp;
					<span
						className="nombre oi navbar-brand bl" data-glyph="person" aria-hidden="true">
						<i > &nbsp;{(usuario.nombre)} {usuario.apellido_paterno} {usuario.apellido_materno} </i>
					</span>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" style={{ position: 'absolute', right: '40px' }} id="navbarNav">
						<div className="navbar-nav ">
							<button className="nav-item nav-link btn salir" href="#" onClick={this.salir}>
								SALIR&ensp;<span className="oi" data-glyph="account-logout" aria-hidden="true"> </span>
							</button>
						</div>
					</div>
				</nav>

				{/* PREGUNTAS DEL TEST */}
				<div className="inicio container-fluid">
					<div className="row espacio"> </div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="row h-100 justify-content-around">
								<div className="col-10 align-self-center ">
									<p className="kau bl ltmd"> TEST:&ensp;ESTILOS DE APRENDIZAJE</p>
									<p className="lat gr3"> RESUELVE ESTE TEST, Y AL FINALIZARLO IMPRIME TU HOJA DE RESULTADOS. </p>
									<p className="lat gr3"> SI YA LO TERMINASTE, PUEDES VOLVER A IMPRIMIR TU HOJA DE RESULTADOS LAS VECES QUE DESEES. </p>
								</div>
							</div>
						</div>
					</div>

					<div className="row justify-content-center pb-5">
						<div className="col-11">
							<form className="container-fluid" onSubmit={this.handleFormSubmit}>
								<div className="card text-center w-100">
									<div className="card-header der"> 
										{
											imprimir?
												<button type="button" className="btn btn-warning" onClick={this.imprimir}>
													<b>Imprimir Resultados</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button> 
											:
												<button type="button" className="btn btn-warning" disabled>
													<b>Imprimir Resultados</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button>
										}
	 								</div>
									<div className="card-body">
										<table>
											{/* TITULO 1 */}
											<thead>
												<tr> <th className="card-title izq"> GRUPO DE PREGUNTAS #1 </th> </tr>
											</thead>
											<tbody>
												{/* PREGUNTA 01 */}
												<tr>
													<td className="pre izq"> Prefiero ver videos en lugar de leer libros </td>
													<td className="res">
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p01" id="p01-1" value="op1" required />
															<label className="form-check-label" for="p01-1">Si</label>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p01" id="p01-2" value="op2" />
															<label className="form-check-label" for="p01-2">No</label>
														</div>
													</td>
												</tr>
												{/* PREGUNTA 02 */}
												<tr>
													<td className="pre izq"> Prefiero ver videos en lugar de leer libros </td>
													<td className="res">
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p02" id="p02-1" value="op1" required />
															<label className="form-check-label" for="p02-1">Si</label>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p02" id="p02-2" value="op2" />
															<label className="form-check-label" for="p02-2">No</label>
														</div>
													</td>
												</tr>
												{/* PREGUNTA 03 */}
												<tr>
													<td className="pre izq"> Prefiero ver videos en lugar de leer libros </td>
													<td className="res">
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p03" id="p03-1" value="op1" required />
															<label className="form-check-label" for="p03-1">Si</label>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="p03" id="p03-2" value="op2" />
															<label className="form-check-label" for="p03-2">No</label>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									{/* BOTON GUARDAR */}
									<div className="card-footer der">
										<button className="btn btn-success" type="submit">
											Guardar &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
										</button>
									</div>
								</div>
							</form>

						</div>
					</div>

				</div>

				{/* PIE DE PAGINA */}
				<PieDePagina />

			</div>

		);
	}

}

export default Resolvertest;