import React from 'react';
import {request, request_file} from '../commonjs/request.js';
import NavigationBar from '../nav/Nav.js';
import PieDePagina from '../footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './solicitud.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';
import Swal from 'sweetalert2';
import ImgHombre from '../../images/hombre.svg';
import ImgMujer from '../../images/mujer.svg';

class Solicitud extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state={
			solicitud :
			{
				id : null,
				ficha : '',
				grupo : '',
				turno : '',
				fechadeinscripcion : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				curp : '',
				fecha : '',
				sexo : '',
				nacionalidad : '',
				entidaddenacimiento : ' ',
				municipiodenacimiento : '',
				calle : '',
				numero : '',
				colonia : '',
				cp : '',
				telefono : '',
				celular : '',
				nss : '',
				correo : '',
				nombrepadre1 : '',
				sexopadre1 : '',
				telefonopadre1 : '',
				domiciliopadre1 : '',
				trabajopadre1 : '',
				telefonotrabajopadre1 : '',
				nombrepadre2 : '',
				sexopadre2 : '',
				telefonopadre2 : '',
				domiciliopadre2 : '',
				trabajopadre2 : '',
				telefonotrabajopadre2 : '',
				contactoemergencia : '',
				telefonoemergencia : '',
				parentescoemergencia : '',
				estadoFoto : '',			// 1 - Sin foto, 2 - En revision, 3 - Aprobada, 4 - Rechazada
				foto : '',
				imprimir : false,
			},
			navmode :
			{
				modo : 0,
				nombre : '',
				apepat : '',
				apemat : '',
				dev : 0,
				oos : 0
			},
			archivo : null,
			botonGuardarFoto : 1
		}
	}

	componentWillMount()
	{
		var {solicitud, navmode} = this.state;
		let temp = this;

		navmode.modo = 3;
		navmode.dev = 1;

		solicitud.ficha = this.props.ficha;

		request.get(`/Solicitud/${solicitud.ficha}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					solicitud = response.data;
					if(!solicitud.id) solicitud.id=null;
					if(parseInt(solicitud.estadoFoto,10) === 0)
					{
						solicitud.estadoFoto = 1;
					}
					else
					{
						solicitud.estadoFoto = parseInt(solicitud.estadoFoto,10);
					}
					if(solicitud.estadoFoto === 1)
					{
						if(solicitud.sexo === "HOMBRE")
							solicitud.foto = ImgHombre;
						else
							solicitud.foto = ImgMujer;
					}
					
					navmode.nombre = solicitud.nombre;
					navmode.apepat = solicitud.apellido_paterno;
					navmode.apemat = solicitud.apellido_materno;
					temp.setState({ solicitud : solicitud, navmode : navmode });
				}
			}
		})
		.catch(error => { });

		request.get(`/MostrarImagen/${solicitud.ficha}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					solicitud.foto = response.data.foto;
					temp.setState({ solicitud : solicitud });
				}
			}
		})
		.catch(error => { });

		this.setState({ solicitud : solicitud, navmode : navmode });	
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var { solicitud } = this.state;

		solicitud[name] = value;

		this.setState({ solicitud : solicitud });
	}

	imprimir=()=>
	{
		let { solicitud } = this.state;

		request_file()
		.post(`/solicitud?numficha=${solicitud.ficha}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var { solicitud } = this.state;
		let temp = this;

		var error=1, mensaje="";


		if(solicitud.entidaddenacimiento.length>=30)
		{
			mensaje="El nombre del pais de nacimiento es muy largo";
		}
		else if(solicitud.municipiodenacimiento.length>=30)
		{
			mensaje="El nombre del municipio de nacimiento es muy largo";
		}
		else if(solicitud.calle.length>=50)
		{
			mensaje="El nombre de la calle es muy largo";
		}		
		else if(solicitud.numero.length>=25)
		{
			mensaje="El numero de la casa es muy largo";
		}
		else if(solicitud.colonia.length>=50)
		{
			mensaje="El nombre de la colonia es muy largo";
		}
		else if(solicitud.nss.length!==11)
		{
			mensaje="El número de seguro social no es válido";
		}
		else if(solicitud.curp.length!==18)
		{
			mensaje="El CURP no es válido";
		}
		else if(solicitud.nombrepadre1.length>=60)
		{
			mensaje="El nombre del primer padre es muy largo";
		}
		else if(solicitud.telefonopadre1.length>=18)
		{
			mensaje="El teléfono del primer padre es muy largo";
		}
		else if(solicitud.domiciliopadre1.length>=60)
		{
			mensaje="La dirección del primer padre es muy larga";
		}
		else if(solicitud.trabajopadre1.length>=40)
		{
			mensaje="El nombre del trabajo del primer padre es muy largo";
		}
		else if(solicitud.telefonotrabajopadre1.length>=18)
		{
			mensaje="El teléfono del trabajo del primer padre es muy largo";
		}
		else if(solicitud.nombrepadre2.length>=60)
		{
			mensaje="El nombre del segundo padre es muy largo";
		}
		else if(solicitud.telefonopadre2.length>=18)
		{
			mensaje="El teléfono del segundo padre es muy largo";
		}
		else if(solicitud.domiciliopadre2.length>=60)
		{
			mensaje="La dirección del segundo padre es muy larga";
		}
		else if(solicitud.trabajopadre2.length>=40)
		{
			mensaje="El nombre del trabajo del segundo padre es muy largo";
		}
		else if(solicitud.telefonotrabajopadre2.length>=18)
		{
			mensaje="El teléfono del trabajo del segundo padre es muy largo";
		}
		else if(solicitud.contactoemergencia.length>=25)
		{
			mensaje="El nombre del contacto de emergencia es muy largo";
		}
		else if(solicitud.telefonoemergencia.length>=18)
		{
			mensaje="El teléfono del contacto de emergencia es muy largo";
		}
		else if(solicitud.parentescoemergencia.length>=25)
		{
			mensaje="El parentesco del contacto de emergencia es muy largo";
		}
		else
		{
			error=0;
		}
		
		if(error === 0)
		{
			request.post('/GuardarSolicitud',solicitud)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
						solicitud.id = response.data.id;
						if(response.data.foto !== null)
						{
							solicitud.foto = response.data.foto;
							solicitud.estadoFoto = response.data.estadoFoto;
							//solicitud.imprimir = 1;
						}

						Swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Los datos fueron guardados',
							showConfirmButton: false,
							timer: 1500
						});
						temp.setState({ solicitud : solicitud });
					}
					else
					{
						Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: 'No se guardo la información.',
							showConfirmButton: false,
							timer: 1500
						});
					}
				}
			})
			.catch(error =>
			{
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: 'No se guardo la información.',
					showConfirmButton: false,
					timer: 1500
				});
			});
		}
		else
		{
			Swal.fire({
				position: 'top-end',
				icon: 'error',
				title: mensaje,
				showConfirmButton: false,
				timer: 2500
			});
		}
	
		this.setState({ solicitud : solicitud });
	}


	fileSelectedHandler=(event)=>
	{
		const target = event.target;
		const temp = target.files[0];

		var { solicitud, archivo, botonGuardarFoto } = this.state;

		if(typeof(temp) !== 'undefined')
		{
			if( (temp.type === 'image/png') || (temp.type === 'image/jpg') || (temp.type === 'image/jpeg') || (temp.type === 'image/gif') )
			{
				if(temp.size < 50000)
				{
					archivo = temp;
					solicitud['foto'] = URL.createObjectURL(archivo);
					solicitud['nombreFoto'] = archivo.name;
					botonGuardarFoto = 2;
					solicitud.estadoFoto=1;
				}
				else
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'La imagen es muy grande',
						showConfirmButton: false,
						timer: 1500
					});
				}
			}
			else
			{
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: 'El tipo de archivo no es valido',
					showConfirmButton: false,
					timer: 1500
				});
			}
			
			this.setState({ solicitud : solicitud, archivo : archivo, botonGuardarFoto : botonGuardarFoto });
		}
	}

	fileUpload=(event)=>
	{
		var { solicitud, archivo, botonGuardarFoto } = this.state;
		let temp = this;

		Swal.fire({
			toast : true,
			title: 'Enviando foto...',
			showClass: { popup: 'animated fadeIn' },
			hideClass: { popup: 'animated fadeOut' },
			timer: 2000,
			onBeforeOpen: () => { Swal.showLoading() },
		})

		solicitud['estadoFoto'] = 1;

		const imageData = new FormData();
		imageData.append('ficha',solicitud.ficha);
		imageData.append('archivo',archivo);

		request.post('/CargarImagen',imageData)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					Swal.fire({
						position: 'center-end',
						icon: 'success',
						title: 'Imagen enviada',
						showConfirmButton: false,
						timer: 2500
					});

					solicitud['foto'] = response.data;
					solicitud['estadoFoto'] = 2;
					solicitud['imprimir'] = false;
					botonGuardarFoto = 1;
					
					temp.setState({ solicitud : solicitud, botonGuardarFoto : botonGuardarFoto });
				}
				else
				{
					Swal.fire({
						position: 'center-end',
						icon: 'error',
						title: 'Hay un problema con la imagen',
						showConfirmButton: false,
						timer: 2500
					});
				}
			}
		})
		.catch(error =>
		{
			Swal.fire({
				position: 'top-end',
				icon: 'error',
				title: 'No se guardo la información.',
				showConfirmButton: false,
				timer: 1500
			});
		});

		this.setState({ solicitud : solicitud, botonGuardarFoto : botonGuardarFoto });
	}
	
	render()
	{
		let { solicitud, navmode } = this.state;

		return (
			<div className="principal">

				{/* MENU SUPERIOR  */}
				{
					navmode.nombre === "" ? '' : <NavigationBar {...navmode} />
				}
				
				<div className="inicio container-fluid padding">

					{/* BLOQUE DE INFORMACION */}
					<div className="row pb-4 justify-content-around">
						<div className="animated fadeIn col-11 align-self-center pt-4 pt-lg-5 pb-4 formMensaje">
							<p className="kau am lg pb-2"> SOLICITUD DE INSCRIPCIÓN</p>
							<p className="lat bl sm">
								LLENA ESTA SOLICITUD CON TUS DATOS, <br/>
								CUANDO HAYAS TERMINADO TODAS LAS ACTIVIDADES
								Y SE HAYA ACREDITADO TU PAGO, <br/>
								PODRAS IMPRIMIRLA.
							</p>
						</div>
					</div>
					<div className="col-12 pt-3 pt-lg-4 pb-3 pb-lg-4">
					</div>

					{/* FORMATO PARA CAPTURA */}
					<div className="row justify-content-center pb-5">
						<div className="col-12 col-sm-12 col-md-11">
							<form onSubmit={this.handleFormSubmit}>
								<div className="card text-center w-100">
									<div className="card-header der"> 
										<button className="btn btn-success mr-1 mr-sm-2 mr-lg-3" type="submit">
											<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
										</button>
										{
											solicitud.imprimir ?
												<button type="button" className="btn btn-warning" onClick={this.imprimir}>
													<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button> 
											:
												""
										}
	 								</div>
									<div className="card-body container-fluid">
										<div className="row pt-2 pb-4">
											<h4 className="col-12 cen gr md"> DATOS GENERALES </h4>
										</div>
										<div className="row">
											<div className="col-8 col-sm-5 col-md-4 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="ficha">No. Ficha</span>
													</div>
													<input type="text" className="form-control" name="ficha" value={solicitud.ficha} aria-label="Ficha" aria-describedby="ficha" disabled/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="semestre">Semestre</span>
													</div>
													<input type="text" className="form-control" value="Primero" aria-label="Semestre" aria-describedby="semestre" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="grupo">Grupo</span>
													</div>
													<input type="text" className="form-control may" name="grupo" value={solicitud.grupo} placeholder="" aria-label="Grupo" aria-describedby="grupo" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="turno">Turno</span>
													</div>
													<input type="text" className="form-control may" name="turno" value={solicitud.turno} placeholder="" aria-label="Turno" aria-describedby="turno" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="especialidad">Especialidad</span>
													</div>
													<input type="text" className="form-control" value="Componente básico" aria-label="Especialidad" aria-describedby="especialidad" disabled/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="periodo">Periodo escolar:</span>
													</div>
													<input type="text" className="form-control" value="Agosto 2023 - Enero 2024 (2023-1)" aria-label="Periodo" aria-describedby="periodo" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="fechadeinscripcion">Fecha de inscripción</span>
													</div>
													<input type="text" className="form-control" name="fechadeinscripcion" value={solicitud.fechadeinscripcion} aria-label="Fechadeinscripcion" aria-describedby="fechadeinscripcion" disabled/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DEL ALUMNO </h4>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombre">Nombre</span>
													</div>
													<input type="text" className="form-control cap negra" value={solicitud.nombre} aria-label="Nombre" aria-describedby="nombre" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="apellido_paterno">Apellido paterno</span>
													</div>
													<input type="text" className="form-control cap negra" value={solicitud.apellido_paterno} aria-label="Apellido_paterno" aria-describedby="apellido_paterno" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="apellido_materno">Apellido materno</span>
													</div>
													<input type="text" className="form-control cap" value={solicitud.apellido_materno} aria-label="Apellido_materno" aria-describedby="apellido_materno" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-7 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="fechadenacimiento">Fecha de nacimiento</span>
													</div>
													<input type="text" className="form-control" value={solicitud.fecha} aria-label="Fechadenacimiento" aria-describedby="fechadenacimiento" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-5 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="sexo">Sexo</span>
													</div>
													<input type="text" className="form-control" value={solicitud.sexo} aria-label="Sexo" aria-describedby="sexo" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-5 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="nacionalidad">Nacionalidad</label>
													</div>
													<select className="custom-select" name="nacionalidad" value={solicitud.nacionalidad} id="nacionalidad" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Mexicano">Mexicano</option>
														<option value="Extranjero">Extranjero</option>
													</select>
												</div>
											</div>
											{
												(solicitud.nacionalidad==="Mexicano")?
													<div className="col-12 col-sm-12 col-md-7 col-xl-6">
														<div className="input-group input-group-sm mb-3">
															<div className="input-group-prepend">
																<label className="input-group-text" htmlFor="entidaddenacimiento">Entidad de nacimiento</label>
															</div>
															<select className="custom-select" name="entidaddenacimiento" value={solicitud.entidaddenacimiento} id="entidaddenacimiento" required onChange={this.handleInputChange}>
																<option value="">Selecciona...</option>
																<option value="Aguascalientes">Aguascalientes</option>
																<option value="Baja California">Baja California</option>
																<option value="Baja California Sur">Baja California Sur</option>
																<option value="Campeche">Campeche</option>
																<option value="Chiapas">Chiapas</option>
																<option value="Chihuahua">Chihuahua</option>
																<option value="Coahuila">Coahuila</option>
																<option value="Colima">Colima</option>
																<option value="Ciudad de Mexico">Ciudad de Mexico</option>
																<option value="Durango">Durango</option>
																<option value="Guanajuato">Guanajuato</option>
																<option value="Guerrero">Guerrero</option>
																<option value="Hidalgo">Hidalgo</option>
																<option value="Jalisco">Jalisco</option>
																<option value="Estado de Mexico">Estado de Mexico</option>
																<option value="Michoacan">Michoacan</option>
																<option value="Morelos">Morelos</option>
																<option value="Nayarit">Nayarit</option>
																<option value="Nuevo Leon">Nuevo Leon</option>
																<option value="Oaxaca">Oaxaca</option>
																<option value="Puebla">Puebla</option>
																<option value="Queretaro">Queretaro</option>
																<option value="Quintana Roo">Quintana Roo</option>
																<option value="San Luis Potosi">San Luis Potosi</option>
																<option value="Sinaloa">Sinaloa</option>
																<option value="Sonora">Sonora</option>
																<option value="Tabasco">Tabasco</option>
																<option value="Tamaulipas">Tamaulipas</option>
																<option value="Tlaxcala">Tlaxcala</option>
																<option value="Veracruz">Veracruz</option>
																<option value="Yucatan">Yucatan</option>
																<option value="Zacatecas">Zacatecas</option>
															</select>
														</div>
													</div>
												:
													<div className="col-12 col-sm-12 col-md-7 col-xl-6">
														<div className="input-group input-group-sm mb-3">
															<div className="input-group-prepend">
																<span className="input-group-text" id="entidaddenacimiento">País de nacimiento</span>
															</div>
															<input type="text" className="form-control" name="entidaddenacimiento" value={solicitud.entidaddenacimiento || ''} aria-label="Entidaddenacimiento" aria-describedby="entidaddenacimiento" required onChange={this.handleInputChange}/>
														</div>
													</div>
											}
											<div className="col-12 col-sm-12 col-md-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="municipiodenacimiento">Municipio<span className="xs i">&nbsp;(de nacimiento)</span></span>
													</div>
													<input type="text" className="form-control" name="municipiodenacimiento" value={solicitud.municipiodenacimiento || ''} aria-label="Municipiodenacimiento" aria-describedby="municipiodenacimiento" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Calle)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Calle" name="calle" value={solicitud.calle  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Num.)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Número" name="numero" value={solicitud.numero  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Col.)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Colonia" name="colonia" value={solicitud.colonia  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(C.P.)</span></span>
													</div>
													
													<input type="text" className="form-control" placeholder="C.P." name="cp" value={solicitud.cp  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefono">Teléfono del alumno</span>
													</div>
													<input type="text" className="form-control" name="telefono" value={solicitud.telefono  || ''} aria-label="Telefono" aria-describedby="telefono" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="celular">Celular del alumno</span>
													</div>
													<input type="text" className="form-control" name="celular" value={solicitud.celular  || ''} aria-label="Celular" aria-describedby="celular" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nss">Número de Seguro Social</span>
													</div>
													<input type="text" className="form-control" name="nss" value={solicitud.nss || ''} aria-label="nss" aria-describedby="nss" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="correo">Correo electrónico</span>
													</div>
													<input type="text" className="form-control" name="correo" value={solicitud.correo || ''} aria-label="Correo" aria-describedby="correo" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="correo">CURP</span>
													</div>
													<input type="text" className="form-control" name="curp" value={solicitud.curp || ''} aria-label="CURP" aria-describedby="curp" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DEL PADRE, MADRE O TUTOR </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm ">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombrepadre1">Nombre</span>
													</div>
													<input type="text" className="form-control" name="nombrepadre1" value={solicitud.nombrepadre1 || ''} aria-label="Nombrepadre1" aria-describedby="nombrepadre1" required onChange={this.handleInputChange}/>
												</div>
												<div className="az izq mb-3 ml-2">
												<span> *Este nombre aparecera en la documentación para inscripción. </span>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="sexopadre1">Sexo</label>
													</div>
													<select className="custom-select" name="sexopadre1" value={solicitud.sexopadre1} id="sexopadre1" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Hombre">Hombre</option>
														<option value="Mujer">Mujer</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonopadre1">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonopadre1" value={solicitud.telefonopadre1 || ''} aria-label="Telefonopadre1" aria-describedby="telefonopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-lg-12 col-xl-9">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domiciliopadre1">Domicilio</span>
													</div>
													<input type="text" className="form-control" name="domiciliopadre1" value={solicitud.domiciliopadre1 || ''} aria-label="Domiciliopadre1" aria-describedby="domiciliopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="trabajopadre1">Lugar de trabajo <span className="xs i">&nbsp;(Nombre de la empresa)</span></span>
													</div>
													<input type="text" className="form-control" name="trabajopadre1" value={solicitud.trabajopadre1  || ''} aria-label="Trabajopadre1" aria-describedby="trabajopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonotrabajopadre1">Teléfono del trabajo</span>
													</div>
													<input type="text" className="form-control" name="telefonotrabajopadre1" value={solicitud.telefonotrabajopadre1 || ''} aria-label="Telefonotrabajopadre1" aria-describedby="telefonotrabajopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DE UN SEGUNDO PADRE, MADRE O TUTOR </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombrepadre2">Nombre</span>
													</div>
													<input type="text" className="form-control" name="nombrepadre2" value={solicitud.nombrepadre2 || ''} aria-label="Nombrepadre2" aria-describedby="nombrepadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="sexopadre2">Sexo</label>
													</div>
													<select className="custom-select" name="sexopadre2" value={solicitud.sexopadre2} id="sexopadre2" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Hombre">Hombre</option>
														<option value="Mujer">Mujer</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonopadre2">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonopadre2" value={solicitud.telefonopadre2 || ''} aria-label="Telefonopadre2" aria-describedby="telefonopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-lg-12 col-xl-9">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domiciliopadre2">Domicilio</span>
													</div>
													<input type="text" className="form-control" name="domiciliopadre2" value={solicitud.domiciliopadre2 || ''} aria-label="Domiciliopadre2" aria-describedby="domiciliopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="trabajopadre2">Lugar de trabajo <span className="xs i">&nbsp;(Nombre de la empresa)</span></span>
													</div>
													<input type="text" className="form-control" name="trabajopadre2" value={solicitud.trabajopadre2  || ''} aria-label="Trabajopadre2" aria-describedby="trabajopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonotrabajopadre2">Teléfono del trabajo</span>
													</div>
													<input type="text" className="form-control" name="telefonotrabajopadre2" value={solicitud.telefonotrabajopadre2 || ''} aria-label="Telefonotrabajopadre2" aria-describedby="telefonotrabajopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DE CONTACTO EN CASO DE EMERGENCIA </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="contactoemergencia">Nombre</span>
													</div>
													<input type="text" className="form-control" name="contactoemergencia" value={solicitud.contactoemergencia || ''} aria-label="contactoemergencia" aria-describedby="contactoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonoemergencia">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonoemergencia" value={solicitud.telefonoemergencia || ''} aria-label="telefonoemergencia" aria-describedby="telefonoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="parentescoemergencia">Parentesco</span>
													</div>
													<input type="text" className="form-control" name="parentescoemergencia" value={solicitud.parentescoemergencia || ''} aria-label="parentescoemergencia" aria-describedby="parentescoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>



										{/*
											solicitud.id !== null ?
												<div>
													<div className="row justify-content-center pt-4">
														<div className="col-12 col-md-9 col-lg-8 col-xl-7 mb-3">

															{
																solicitud.estadoFoto === 1 ?
																	<img className="rounded img-thumbnail marcoFoto" src={solicitud.foto} alt="Tu foto" width="200" height="200"/>
																:
																	<img className="rounded img-thumbnail marcoFoto" src={`data:image/jpeg;base64,${solicitud.foto}`} alt="Tu foto" width="200" height="200"/>
															}
																
																
															<button type="button" className="botonFoto" disabled>
																{	solicitud.estadoFoto === 1 ? <span className="oi md am" data-glyph="warning"> </span> : "" }
																{	solicitud.estadoFoto === 2 ? <span className="oi md am" data-glyph="clock"> </span> : "" }
																{	solicitud.estadoFoto === 3 ? <span className="oi md verde" data-glyph="check"> </span> : "" }
																{	solicitud.estadoFoto === 4 ? <span className="oi md ro" data-glyph="x"> </span> : "" }
															</button>
															
																botonGuardarFoto === 2 ?
																	<div className="row justify-content-center mb-4">
																		<div className="col-12 col-md-9 col-lg-8 col-xl-7">
																			<button type="button" className="btn btn-success" onClick={this.fileUpload}>
																				<b>Enviar foto para validar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
																			</button>
																		</div>
																	</div>
																:
																	""
															
														</div>
													</div>
													{
														solicitud.estadoFoto === 2 ?
															<div className="row justify-content-center mb-4">
																<div className="col-12 col-md-9 col-lg-8 col-xl-5 alert alert-warning" role="alert">
																	Tu foto aun no ha sido revisada, danos algo de tiempo.
																</div>
															</div>
														:
															""
													}
													
														solicitud.estadoFoto === 4 ?
															<div className="row justify-content-center mb-4">
																<div className="col-12 col-md-9 col-lg-8 col-xl-5 alert alert-danger" role="alert">
																	Tu foto fue rechazada, debes subir una diferente.
																</div>
															</div>
														:
															""
													
													<div className="row justify-content-center">
														<div className="col-12 col-md-9 col-lg-8 col-xl-5">
															<div className="input-group input-group-sm mb-3">
																<div className="input-group-prepend">
																	<span className="input-group-text" id="agregarFoto">Agrega una fotografia</span>
																</div>
																<div className="custom-file">
																	<input type="file" className="custom-file-input" id="foto" aria-describedby="agregarFoto" onChange={this.fileSelectedHandler}/>
																	<label className="custom-file-label izq" htmlFor="foto"> {solicitud.nombreFoto} </label>
																</div>
															</div>
														</div>
													</div>
											//	</div>
											:
												""
										*/}

									</div>
									

									{/* BOTON GUARDAR */}
									<div className="card-footer der">
										<button className="btn btn-success mr-1 mr-sm-2 mr-lg-3" type="submit">
											<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
										</button>
										{
											// solicitud.imprimir ?
											// 	<button type="button" className="btn btn-warning" onClick={this.imprimir}>
											// 		<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
											// 	</button> 
											// :
											// 	""
										}
									</div>
								</div>
							</form>

						</div>
					</div>

				</div>

				{/* PIE DE PAGINA */}
				<PieDePagina />

			</div>

		);
	}

}

export default Solicitud;