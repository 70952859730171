import React from 'react';
import './footer.css';
import '../commoncss/fonts.css';

class Footer extends React.Component{

	render()
	{
		return (
			<div className="footershadow">

				{/* INFORMACION DE CONTACTO */}
				<div id="contacto" className="contacto container-fluid">
					<div className="row pt-4 pb-4">
						<div className="col-12 text-center pt-3 md contactoTitulo">
							Contáctanos
						</div>
						<div className="col-12 text-center pt-3 sm">
							<span className="oi am" data-glyph="map-marker" aria-hidden="true"></span>
							&nbsp;&nbsp;&nbsp;Carr. Transpeninsular Km. 115, Ex-Ejido Chapultepec,
						</div>
						<div className="col-12 text-center sm">
							C.P. 22875, Ensenada, Baja California, México.
						</div>
						<div className="col-12 text-center pt-3 sm"> 
							<span className="oi am" data-glyph="phone" aria-hidden="true"></span>
							&nbsp;&nbsp;&nbsp;(646)-176-61-76
						</div>
						<div className="col-12 text-center pt-3 sm">
							<span className="oi am" data-glyph="clock" aria-hidden="true"></span>
							&nbsp;&nbsp;&nbsp;9:00 a.m. - 5:00 p.m.
						</div>
						<div className="col-12 text-center pt-3 sm"> 
							<span className="oi am" data-glyph="envelope-closed" aria-hidden="true"></span>
							&nbsp;&nbsp;&nbsp;ingresocbtis41@gmail.com
						</div>
					</div>
				</div>

				{/* INFORMACION DE DESARROLLO */}
				<div id="derechos" className="derechos container">
					<div className="row no-gutters pt-4 pb-4 xs">
						{/* DEVELOPED... */}
						<div className="col-12 text-center">
							Developed for&nbsp;
							<font className="am"> <span className="oi" data-glyph="monitor" aria-hidden="true"> </span> </font> 
							&nbsp;,&nbsp;
							<font className="am"> <span className="oi" data-glyph="tablet" aria-hidden="true"> </span> </font> 
							&nbsp;& &nbsp;
							<font className="am"> <span className="oi" data-glyph="phone" aria-hidden="true"> </span> </font>
							&nbsp;with&nbsp; 
							<font className="ro"> <span className="oi" data-glyph="heart" aria-hidden="true"> </span> </font> 
							&nbsp;at&nbsp;
							<font className="am"> <span className="oi" data-glyph="home" aria-hidden="true"> </span> </font>
							&nbsp;By&nbsp; 
							<font className="am"> PapOn </font>; 
						</div>

						{/* POWERED REACT JS */}
						<div className="col-12 col-sm-6 textoiz">
							<span className="oi am" data-glyph="bolt" aria-hidden="true"></span>
							&nbsp;by&nbsp;
							<font className="am">React&nbsp;</font>
							&
							<font className="am"> JS&nbsp;</font>;&nbsp;
						</div>

						{/* CODED HTML */}
						<div className="col-12 col-sm-6 textoder">
							&nbsp;&nbsp;<span className="oi am" data-glyph="code" aria-hidden="true"></span>
							&nbsp;&nbsp;with&nbsp;
							<font className="am">Html&nbsp;</font>;
						</div>

						{/* DESIGNED BOOTSRTAP CSS */}
						<div className="col-12 col-sm-6 textoiz">
							&nbsp;<span className="oi am" data-glyph="brush" aria-hidden="true"> </span>
							&nbsp;with&nbsp;
							<font className="am"> CSS</font>
							&nbsp;,&nbsp;
							<font className="am blogo"> <span className="oi" data-glyph="bold" aria-hidden="true"> </span> </font>
							&nbsp;&nbsp;&
							<font className="am"> OpenIconic&nbsp;</font>;&nbsp;
						</div>

						{/* MANAGED MYSQL LARAVEL */}
						<div className="col-12 col-sm-6 textoder">
							&nbsp;&nbsp;<span className="oi am" data-glyph="link-intact" aria-hidden="true"> </span>
							&nbsp;with&nbsp;
							<font className="am">MySql </font>
							&
							<font className="am"> Laravel&nbsp;</font>;
						</div>

						{/* PAPON */}
						<div className="col-12 text-center">
							<font className="am"> PapOn Dev. </font>
							MMXXIII.
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default Footer;