import React from 'react';
import WindowSizeListener from 'react-window-size-listener';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import '../../fonts/css/open-iconic.css';
import '../commoncss/fonts.css';
import '../commoncss/animate.min.css';
import './nav.css';
import Swal from 'sweetalert2';


class Nav extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			navmode :
			{
				modo : 0,
				nombre : '',
				apepat : '',
				apemat : '',
				dev : 0,
				oos : 0
			},
			ancho : 0,
		}
	}

	componentWillMount()
	{
		var { navmode } = this.state;

		navmode.modo = this.props.modo;
		navmode.nombre = this.props.nombre;
		navmode.apepat = this.props.apepat;
		navmode.apemat = this.props.apemat;
		navmode.dev = this.props.dev;
		navmode.oos = this.props.oos;

		this.setState({ navmode : navmode });
	}

	salirDeSolicitud=()=>
	{
		Swal.fire({
			position: 'top-end',
			title: 'Si no guardas los cambios, se perderán los datos',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#13A703',
			confirmButtonText: 'Salir de la captura',
			cancelButtonText: 'Continuar en esta página',
			showClass: {
				popup: 'animated fadeInDown faster'
			},
			hideClass: {
				popup: 'animated fadeOutUp faster'
			}
		}).then((result) => {
			if (result.value)
			{
				window.location="/";
			}
		})
	}

	render(){

		let { navmode, ancho } = this.state;

		return (
			<div>

				<WindowSizeListener onResize={(windowSize) => this.setState({ancho : windowSize.windowWidth}) } />

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark navshadow">

					{/* MARCADORES DE TAMAÑO - 1 MODO DESARROLLO - VISIBLES */}
					{
						navmode.dev === 1 ?
						<span>
							<span className="oi mark mark1" data-glyph="media-record" aria-hidden="true"> </span>
							<span className="oi mark mark2" data-glyph="media-record" aria-hidden="true"> </span>
							<span className="oi mark mark3" data-glyph="media-record" aria-hidden="true"> </span>
							<span className="oi mark mark4" data-glyph="media-record" aria-hidden="true"> </span>
							<span className="oi mark mark5" data-glyph="media-record" aria-hidden="true"> </span>
						</span>
						:
						<span></span>
					}

					{/* ETIQUETA DE NOMBRE */}
					{/* 1 - MENU PRINCIPAL - INICIO */}
					{/* 2 - USUARIO IDENTIFICADO - NOMBRE DE USUARIO */}
					{
						navmode.modo === 1 ?
							<a className="nav-item nav-link b" href="#inicio">INICIO</a>
						:
							""
					}
					{
						navmode.modo > 1 ?
							ancho > 767 ?
								<span className="nav-link capNav oi sm h-100 align-middle i" data-glyph="person" aria-hidden="true">
									&nbsp;&nbsp;{(navmode.nombre)} 
									<span className="capNav"> {navmode.apepat} </span>
									<span className="capNav"> {navmode.apemat} </span>
								</span>
							:
								<span className="nav-link capNav oi i sm h-100 align-middle" data-glyph="person" aria-hidden="true">
									&nbsp;{(navmode.nombre)}
								</span>
						:
							""
					}

					{/* BOTON DE MENU - 9 SIN MENU PARA FUERA DE SERVICIO*/}
					{
						navmode.oos === 0 ?
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
									<span className="navbar-toggler-icon"></span>
							</button>
							:
							""
					}
					<div className="collapse navbar-collapse justify-content-end" id="navbarNav">

						<div className="navbar-nav der">

							{/* Opcion Resultados*/}
							{/*
								navmode.modo === 1 ?
									<a className="nav-item nav-link margen" href="http://fichas.cbtis41.net">
										<span className="oi" data-glyph="document" aria-hidden="true">&nbsp;&nbsp;Tramita Tu Ficha</span>
									</a>
								:
									""
							*/}

							{/* Opcion Moodle*/}
							{/*
								navmode.modo === 1 ?
									<a className="nav-item nav-link margen" href="http://200.76.255.166/login/index.php">
										<span className="oi" data-glyph="book" aria-hidden="true">&nbsp;&nbsp;Moodle en CBTIS 41</span>							
									</a>
								:
									""
							*/}

							{/* Opcion Resultados*/}
							{
								navmode.modo === 1 ?
									<a className="nav-item nav-link margen" href="#resultados">
										<span className="oi" data-glyph="list" aria-hidden="true">&nbsp;&nbsp;Consulta De Resultados</span>
									</a>
								:
									""
							}

							{/* Opcion Contacto*/}
							{
								navmode.modo === 1 ?
									<a className="nav-item nav-link margen" href="#contacto">
										<span className="oi" data-glyph="people" aria-hidden="true">&nbsp;&nbsp;Contacto</span>							
									</a>
								:
									""
							}

							{/* Opcion Salir De Descargas */}
							{
								navmode.modo === 2 ?
									<a className="nav-item nav-link margen" href="/" >
										<span className="oi" data-glyph="power-standby" aria-hidden="true">&nbsp;&nbsp;SALIR</span>
									</a>
								:
									""
							}

							{/* Opcion Salir De Solicitud */}
							{
								navmode.modo === 3 ?
									<button className="nav-item margen nav-link nav" onClick={this.salirDeSolicitud}>
										<span className="oi" data-glyph="action-undo" aria-hidden="true">&nbsp;&nbsp;SALIR</span>
									</button>
								:
									""
							}

						</div>

					</div>
					
				</nav>
			</div>
		);
	}
}

export default Nav;