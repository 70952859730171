import React from 'react';
import {request, request_file} from '../commonjs/request.js';
import Solicitud from '../solicitud/Solicitud.js';
import NavigationBar from '../nav/Nav.js';
import PieDePagina from '../footer/Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './descargas.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';
import Swal from 'sweetalert2';
import WOW from 'wow.js/dist/wow.min.js';
import calendario from './../../images/calendario.jpg';
// import induccion from './../../images/induccion.jpg';
//import postest from './../../images/postest.jpg';
import instruccion1 from './../../images/instruccion1.jpg';
import instruccion2 from './../../images/instruccion2.jpg';
import instruccion3 from './../../images/instruccion3.jpg';

class Descargas extends React.Component{

	constructor(props){
		super(props);

		this.state={
			usuario :
			{
				ficha : '',
				num_control : '',
				grupo : '',
				turno : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				curp : '',
				referencia : null,
				cantidad : 0,
				check1 : 0,
				check2 : 0,
				check3 : 0,
				check4 : 0,
				check5 : 0,
				check6 : 0,
				check7 : 0,
				check8 : 0,
				check9 : 0,
				check10 : 0,
				check11 : 0,
				check12 : 0,
				check13 : 0,
				check14 : 0,
				check15 : 0,
				check16 : 0,
				check17 : 0,
				check18 : 0,
				check19 : 0,
				check20 : 0,
				check21 : 0,
				check22 : 0,
				check23 : 0,
				check24 : 0,
				check25 : 0,
				observaciones : '',
				check_ref : 0,
			},
			navmode :
			{
				modo : 2,
				nombre : '',
				apepat : '',
				apemat : '',
				dev : 1,
				oos : 0
			},
			correo :
			{
				correoi : null,
				password : null,
			},
			calificaciones : 
			{
				recsos : null,
			},
			pass_retest : null,
			barra : 0,
			max_docs : 9,
			redireccion : 0,
			progreso : 0,
			generando : 0,
			//mensajeDeAvance : 'Revisa todas las ligas para descargar la referencia bancaria'
			mensajeDeAvance : 'LLena primero la solicitud de inscripción'
		}
		this.descargar = this.descargar.bind(this);
		this.revisarCheck = this.revisarCheck.bind(this);
		this.mensajeInicial = this.mensajeInicial.bind(this);
		this.pedirfoto = this.pedirfoto.bind(this);

	}

	componentDidMount()
	{
		new WOW({ 
			boxClass:     'wow',      // default
			animateClass: 'animated', // default
			offset:       100,          // default
			mobile:       true,       // default
			live:         false       // default - true
		}).init();

		var { usuario, navmode, barra, progreso, mensajeDeAvance, max_docs } = this.state;
		let temp = this;

		usuario.ficha = this.props.ficha;

		request.get('/buscarsp/'+usuario.ficha) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				usuario = response.data;

				navmode.nombre = usuario.nombre;
				navmode.apepat = usuario.apellido_paterno;
				navmode.apemat = usuario.apellido_materno;

				usuario.check1 = parseInt(usuario.check1,10);
				usuario.check2 = parseInt(usuario.check2,10);
				usuario.check3 = parseInt(usuario.check3,10);
				usuario.check4 = parseInt(usuario.check4,10);
				usuario.check5 = parseInt(usuario.check5,10);
				usuario.check6 = parseInt(usuario.check6,10);
				usuario.check7 = parseInt(usuario.check7,10);
				usuario.check8 = parseInt(usuario.check8,10);
				usuario.check9 = parseInt(usuario.check9,10);
				usuario.check10 = parseInt(usuario.check10,10);
				usuario.check11 = parseInt(usuario.check11,10);
				usuario.check12 = parseInt(usuario.check12,10);
				usuario.check13 = parseInt(usuario.check13,10);
				usuario.check14 = parseInt(usuario.check14,10);
				usuario.check15 = parseInt(usuario.check15,10);
				usuario.check16 = parseInt(usuario.check16,10);
				usuario.check17 = parseInt(usuario.check17,10);
				usuario.check18 = parseInt(usuario.check18,10);
				usuario.check19 = parseInt(usuario.check19,10);
				usuario.check20 = parseInt(usuario.check20,10);
				usuario.check21 = parseInt(usuario.check21,10);
				usuario.check22 = parseInt(usuario.check22,10);
				usuario.check23 = parseInt(usuario.check23,10);
				usuario.check24 = parseInt(usuario.check24,10);
				usuario.check25 = parseInt(usuario.check25,10);
				usuario.check_ref = parseInt(usuario.check_ref,10);

				barra = usuario.check1  +  usuario.check2 +  usuario.check3 + usuario.check4 + 
						usuario.check7 +  usuario.check8 + usuario.check9 + usuario.check10 + 
						usuario.check12 + usuario.check14 + usuario.check15 + usuario.check19 + 
						usuario.check20 + usuario.check23 + usuario.check25;

				
				if(usuario.check16 === 1) barra++;
				if(usuario.check17 === 1) barra++;
				if(usuario.check18 === 1) barra++;
				if(usuario.check21 === 1) barra++;

				progreso=100/max_docs*barra;

				// if( usuario.check1===1 && usuario.check4===1 && usuario.check5===1 && usuario.check6===1 && usuario.check7===1 &&
				// 	usuario.check8===1 && usuario.check9===1 && usuario.check10===1 && usuario.check12===1 && usuario.check13===1 &&
				// 	usuario.check14===1 && usuario.check15===1 && usuario.check16===1 && usuario.check17===1 && usuario.check18===1 &&
				// 	usuario.check19===1 && usuario.check20===1 && usuario.check21===1)
				// {
					//mensajeDeAvance="La referencias de pago estaran disponibles hasta el dia 9 de septiembre.";
					//mensajeDeAvance="Llena, guarda e imprime tu solicitud de inscripción para descargar la referencia bancaria";
				// }
				// if( usuario.check1===1 && usuario.check3===1 && usuario.check4===1 && usuario.check5===1 && usuario.check6===1 &&
				// 	usuario.check7===1 && usuario.check8===1 && usuario.check9===1 && usuario.check10===1 && usuario.check12===1 &&
				// 	usuario.check13===1 && usuario.check14===1 && usuario.check15===1 && usuario.check16===1 && usuario.check17===1 &&
				// 	usuario.check18===1 && usuario.check19===1 && usuario.check20===1 && usuario.check21===1)
				// {
				// 	usuario.check_ref=1;
				// }

				temp.setState({  usuario : usuario, navmode : navmode, barra : barra, progreso : progreso, mensajeDeAvance : mensajeDeAvance });
			}
		})
		.catch(error => { });

		this.mensajeInicial();
	}

	mensajeInicial()
	{
		// MENSAJE INICIAL

		// Reunion informativa
	
		// Swal.fire({
		// 	title: '<strong>Reunión* informativa de carácter obligatorio</strong>',
		// 	icon: 'info',
		// 	html: '<b><b>Temas:</b></b><br>' + 
		// 		  'Inicio de semestre<br>'+
		// 		  'Inscripciones<br>'+
		// 		  'Acta de acuerdo del proyecto de inversión<hr><b>'+
		// 		  'Dia jueves 04 de julio.<br><br>'+
		// 		  'Turno Matutino 8:00 hrs.<br>'+
		// 		  'Turno Vespertino 18:00 hrs.</b><hr>',
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok',
		// 	footer: '<b>*La reunión es solo para padres de familia</b>',
		// });

		// Swal.fire({
		// 	title: '<strong>Entrega de documentos</strong>',
		// 	icon: 'info',
		// 	html: '<br><br>' + 
		// 		  'A partir del <b>martes 17</b> podras comenzar a descargar los documentos que necesitas para inscribirte, '+
		// 		  'debes reunirlos todos para que puedas entregarlos.<br><br>'+
		// 		  'La <b>entrega de documentos sera del 23 al 27 de Agosto</b>, revisa el calendario para que veas cuando te toca.<br><br>',
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok',
		// });

		// 	Swal.fire({
		// 		title: '<strong>Actualización de requisitos</strong>',
		// 		icon: 'info',
		// 		html: '<br><br>' + 
		// 			  'La lista de requisitos ha sido actualizada, <b>descargala nuevamente</b> '+
		// 			  'para que la revises y entregues la correcta.'+
		// 			  '<br><br>',
		// 		focusConfirm: false,
		// 		confirmButtonText: 'Ok',
		// });

		// Swal.fire({
		// 	width: '800px',
		// 	html: ' <img src="'+ postest +'">',
		// 	footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
		// });

		// Swal.fire({
		// 	icon: 'warning',
		// 	text: 'Carga tu foto para tramitar tu credencial escolar'
		// });

		// 	Swal.fire({
		// 		title: '<strong><br/>VALIDACIÓN DE PAGOS</strong>',
				
		// 		html: '<br><br>' + 
		// 			  'Debido a una actualización con nuestro sistema bancario,  ' +
		// 			  '<b>los pagos serán validados</b> hasta el día <b>viernes 29</b> de julio.<br/><br/>' +
		// 			  'No te desesperes!!!,<br/> mientras tanto, completa los demás requisitos.' +
		// 			  '<br><br>',
		// 		focusConfirm: false,
		// 		confirmButtonText: 'Ok',
		// });

	}

	descargar(event,numarchivo)
	{
		var archivo='';
		switch(numarchivo)
		{
			case  1: archivo='Listadecotejo'; break;
			case  2: /* Referencia de pago */ break; 
			case  3: /* Solicitud de inscripcion */ break;
			case  4: archivo='Normas_de_convivencia'; break;
			case  5: archivo='Calendario'; break;
			case  6: /* Test: Estilos de aprendizaje */ break;
			case  7: archivo='Manual_Del_Estudiante'; break;
			case  8: archivo='Manual_Caso_1'; break;
			case  9: archivo='Manual_Caso_2'; break;
			case 10: archivo='Manual_Caso_3'; break;
			case 11: archivo='Estudio_Socioeconomico'; break;
			case 12: /* Liga: Seguro social */ break;
			case 13: /* Liga: Actualizacion de datos */ break;
			case 14: archivo='Aviso_De_Privacidad'; break;
			case 15: archivo='No_Registro_Dgeti'; break;
			case 16: /* Acta de nacimiento */  break;
			case 17: /* Certificado de secundaria */ break;
			case 18: /* CURP */  break;
			case 19: /* Liga: Encuesta Orientacion Educativa */  break;
			case 20: archivo='Netiqueta'; break;
			case 21: /* Comprobante de pago */  break;
			case 22: archivo='Carta_Responsiva'; break;
			case 23: /* Fotografia */; break;
			case 24: /* Comprobante de realizacion de tramites*/  break;
			case 25: archivo='Consentimiento';  break;
			default:
		}

		//if((numarchivo == 4) || (numarchivo == 25) || (numarchivo == 14) || (numarchivo == 15))
		// {
		// 	Swal.fire({
		// 		toast: true,
		// 		icon: 'warning',
		// 		title: 'Aun no esta disponible esta descarga',
		// 		showConfirmButton: false,
		// 		timer: 3000
		// 	});
		// }
		// else
		// {
			request_file()
			.post(`/descargar?archivo=${archivo}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL,"archivo.pdf");
				this.revisarCheck(numarchivo);
			})
			.catch(error => {
				console.log(error);
			});
		// }

	}

	decargarReferencia=()=>
	{
		let {usuario} = this.state;

	// Swal.fire({
	// 				toast: true,
	// 				icon: 'warning',
	// 				title: 'El periodo para descarga de referencias ha concluido, espera el siguiente.',
	// 				showConfirmButton: false,
	// 				timer: 3000
	// 			});

		// if(usuario.check3 === 1)
		// {
			if(parseInt(usuario.referencia,10) === 1)
			{
				request_file()
				//.post('/referenciaform') // Para descargar formato de inscripción en blanco
				.post(`/referencia?numficha=${usuario.ficha}`)
				.then(response => {
					if(response.data)
					{
						const file = new Blob([response.data], {type: 'application/pdf'});
						const fileURL = URL.createObjectURL(file);
						window.open(fileURL);
						//this.revisarCheck(2);
					}
				})
				.catch(error => {
					console.log(error);
				});
			}
			else
			{
				Swal.fire({
					toast: true,
					icon: 'warning',
					title: 'Si quieres descargar tu referencia bancaria comunicate con nosotros.',
					showConfirmButton: false,
					timer: 3000
				});	
			}
		// }
		// else
		// {
		// 	Swal.fire({
		// 		toast: true,
		// 		icon: 'warning',
		// 		title: 'No has llenado tu solicitud de inscripción.',
		// 		showConfirmButton: false,
		// 		timer: 3000
		// 	});
		// }
	}

	descargarHistorial=()=>
	{
		let { usuario } = this.state;

		Swal.fire({
			
			icon: 'warning',
			title: 'AVISO',
			html: 'La materia de <b> RECURSOS SOCIOEMOCIONALES I </b> necesita ser reajustada, por lo que temporalmente aparecera como <b> N/A </b> en el historial.'+
				  '<br><br> Revisa tu calificación en la opción correspondiente.',
			showConfirmButton: true
		}).then((result) => {

		// if(parseInt(usuario.semestre,10) === 3)
		// {
		// 	Swal.fire({
		// 		toast: true,
		// 		icon: 'warning',
		// 		title: 'Recuerda que si eres de segundo semestre debes acudir al plantel por tu boleta.',
		// 		showConfirmButton: false,
		// 		timer: 3000
		// 	});
		// }
		// else
		// {
			request_file()
			.post(`/descargarHistorial?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(error => {
				console.log(error);
				Swal.fire({
					toast: true,
					icon: 'warning',
					title: 'Tu historial aun no se encuentra disponible',
					showConfirmButton: false,
					timer: 3000
				});

			});
		});

		// }
	}

	decargarReferenciaCredencial=()=>
	{
		let {usuario} = this.state;

		request_file()
		.post(`/referenciaCredencial?numficha=${usuario.ficha}`)
		.then(response => {
			if(response.data)
			{
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			}
		})
		.catch(error => {
			console.log(error);
		});
	}


	consultar_clave_para_examen=()=>
	{
		let { usuario } = this.state;
		var { pass_retest } = this.state;
		let temp = this;

		request.get('/Retest/'+usuario.ficha) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				console.log(response.data);
				if(Object.keys(response.data).length === 0)
				{
					Swal.fire({
						toast: true,
						icon: 'warning',
						title: 'No cuentas con acceso al examen, debes esperar la siguiente aplicación.',
						showConfirmButton: false,
						timer: 3000
					});
				}
				else
				{
					pass_retest = response.data;
					Swal.fire({
						title: '<strong>Postest de Nuevo Ingreso</strong>',
						html:   `<br>`+
								`Entra a <br><br>` +
								`<b><a href="http://ingreso.cosfac.sems.gob.mx/evaluacionposttest" target="_blank"> http://ingreso.cosfac.sems.gob.mx/evaluacionposttest </a></b><br><br>` +
								`para que puedas ingresar a tu examen,<br>`+
								`ahi debes escribir la siguiente clave de acceso:<br><br>`+
								`<b>${pass_retest}</b><br><br>`,
						focusConfirm: false
					});
				}

				temp.setState({  pass_retest : pass_retest });
			}
		})
		.catch(error => { });
	}

	consultar_calendario=()=>
	{
		Swal.fire({
			width: '800px',
			html: ' <img src="'+ calendario +'" width="95%">'
		});
	}

	descargarHorario=()=>
	{
		let { usuario } = this.state;
		
		request_file()
		.post(`/DescargarHorario?ficha=${usuario.ficha}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"archivo.pdf");
		})
		.catch(error => {
			console.log(error);
		});
		// Swal.fire({
		// 	toast: true,
		// 	icon: 'warning',
		// 	title: 'Aun no esta listo tu horario de clase',
		// 	showConfirmButton: false,
		// 	timer: 3000
		// });
	}

	consultarCorreo=()=>
	{
		let { usuario } = this.state;
		var { correo } = this.state;
		let temp = this;

		request.get('/CorreoInstitucional/'+usuario.ficha) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				correo = response.data;
				if(correo.correoi === null)
				{
					Swal.fire({
						toast: true,
						icon: 'warning',
						title: 'Aun no se genera tu correo institucional',
						showConfirmButton: false,
						timer: 3000
					});
				}
				else
				{
				Swal.fire({
					title: '<strong>Activa tu Correo Institucional</strong>',
					html:   `<br>`+
							`Entra a <br>` +
							// <b><a href="https://accounts.google.com/ServiceLogin?service=mail&passive=true&Email=${correo.correoi}&continue=https://mail.google.com/mail/u/${correo.correoi}/" target="_blank"> https://mail.google.com </a></b><br><br>
							`<b><a href="https://mail.google.com" target="_blank"> https://mail.google.com </a></b><br><br>` +
							`y activa tu cuenta de correo <b>`+
							`<br>${correo.correoi}</b><br><br>` +
							`utilizando la contraseña<br>`+ 
							`<b>${correo.password}</b>` +
							`<br><br>`,
					focusConfirm: false,
					footer: 'Si tienes algun problema con tu correo escribenos a: correos.soporte@cbtis041.edu.mx<br>'+
							'agregando tu nombre completo y grupo.',
				});
			}

				temp.setState({  correo : correo });
			}
		})
		.catch(error => { });
	}

	consultarCalificacion=()=>
	{
		let { usuario } = this.state;
		var { calificaciones } = this.state;
		let temp = this;
		var mensaje="(Materia NO acreditada)";

		request.get('/CalificacionSocioemocional/'+usuario.ficha) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				calificaciones = response.data;
				if(calificaciones.recsos == "AC")
				{
					mensaje="(Materia acreditada)";
				}

				Swal.fire({
					title: '<strong>RECURSOS SOCIOEMOCIONALES I</strong>',
					html:   `<br>`+
							`Tu estado en esta materia es:<br><br>`+
							`<b><br>${calificaciones.recsos}</b><br>`+
							`<b><br>${mensaje}</b><br><br>`,
					focusConfirm: false,
					footer: 'Si el estado de la materia es NAC significa que no la acreditaste.<br>'+ 
							'No existen Exámenes Extraordinarios ni Recursamientos intersemestrales para esta materia, '+
							'por lo que tendrás que repetirla en el semestre 2024-1 (Ago-Dic 2024).',
				});
				
				temp.setState({  calificaciones : calificaciones });
			}
		})
		.catch(error => { });
	}

	llenarSolicitud=()=>
	{
		// Swal.fire({
		// 	toast: true,
		// 	icon: 'warning',
		// 	title: 'La solicitud la podras llenar a partir del 8 de septiembre.',
		// 	showConfirmButton: false,
		// 	timer: 4000
		// });

		var {redireccion} = this.state;
		redireccion	= 3;
		this.setState({ redireccion : redireccion });
	}

	subirDocumento = async (op)=>
	{
		var { usuario } = this.state;
		let temp = this;
		var titulo = '', tipo = '';

		switch(op)
		{
			case 1: titulo = 'Adjuntar acta de nacimiento'; break;
			case 2: titulo = 'Adjuntar certificado de secundaria'; break;
			case 3: titulo = 'Adjuntar CURP'; break;
			case 4: titulo = 'Adjuntar comprobante de pago'; break;
			case 5: titulo = 'Adjuntar comprobante de NSS'; break;
			default:
		}

		try {

			if(op === 4)
			{
				tipo = 'image/jpeg' ;
			}
			else
			{
				tipo = 'application/pdf'
			}
			const { value: archivo } = await Swal.fire({
												title: titulo,
												input: 'file',
												inputAttributes:
												{
													'accept': tipo,
													'aria-label': 'Upload your profile picture'
												}
											})
			
			if (archivo) 
			{
				const fileData = new FormData();
				fileData.append('ficha',usuario.ficha);
				fileData.append('tipo',op);
				fileData.append('archivo',archivo);

				request.post('/CargarPdf',fileData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								position: 'center-end',
								icon: 'success',
								title: 'Documento enviado',
								showConfirmButton: false,
								timer: 2500
							});
							switch(op)
							{
								case 1: usuario.check16 = 2; break;
								case 2: usuario.check17 = 2; break;
								case 3: usuario.check18 = 2; break;
								case 4: usuario.check21 = 2; break;
								case 5: usuario.check12 = 1; break;
								default:
							}
							temp.setState({  usuario : usuario });
						}
						else
						{
							Swal.fire({
								position: 'center-end',
								icon: 'error',
								title: 'El archivo no es válido',
								showConfirmButton: false,
								timer: 2500
							});
						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No pudo enviarse el archivo',
						showConfirmButton: false,
						timer: 1500
					});
				});

				// const reader = new FileReader()
				// reader.onload = (e) => {
				// 	Swal.fire({
				// 		title: 'Your uploaded picture',
				// 		imageUrl: e.target.result,
				// 		imageAlt: 'The uploaded picture'
				// 	})
				// }
				// reader.readAsDataURL(file)
			}
			else
			{
				Swal.fire('No se selecciono ningun archivo');
			}
		} catch (e) {
			console.log('error:', e);
			return false;
		}

	}

	subirFotografia = ()=>
	{
		var { usuario } = this.state;

		if(usuario.check23 === 4)
		{
			Swal.fire({
				width: '600px',
				html: `<b>Motivo de rechazo de tu fotografia:</b><br><br>`+
						`<i>${usuario.observaciones}</i>`+
						`<br><br>`,


				confirmButtonText: 'Enterado'
			}).then((result) => {
				Swal.fire({
					width: '600px',
					html: ' <img src="'+ instruccion1 +'" width="500" height="600">',
					footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
					confirmButtonText: 'Siguiente...'
				}).then((result) => {
					if(result.value)
					{
						Swal.fire({
							width: '600px',
							html: ' <img src="'+ instruccion2 +'" width="500" height="600">',
							footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
							confirmButtonText: 'Siguiente...'
						}).then((result) => {
							if(result.value)
							{
								Swal.fire({
									width: '600px',
									html: ' <img src="'+ instruccion3 +'" width="500" height="600">',
									footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
									confirmButtonText: 'Entendido'
								}).then((result) => {
									if(result.value)
									{
										this.pedirfoto();
									}
								})
							}
						})
					}
				})
			});
		}
		else
		{
			Swal.fire({
				width: '600px',
				html: ' <img src="'+ instruccion1 +'" width="500" height="600">',
				footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
				confirmButtonText: 'Siguiente...'
			}).then((result) => {
				if(result.value)
				{
					Swal.fire({
						width: '600px',
						html: ' <img src="'+ instruccion2 +'" width="500" height="600">',
						footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
						confirmButtonText: 'Siguiente...'
					}).then((result) => {
						if(result.value)
						{
							Swal.fire({
								width: '600px',
								html: ' <img src="'+ instruccion3 +'" width="500" height="600">',
								footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
								confirmButtonText: 'Entendido'
							}).then((result) => {
								if(result.value)
								{
									this.pedirfoto();
								}
							})
						}
					})
				}
			});
		}
	}

	async pedirfoto()
	{
		var { usuario } = this.state;
		let temp = this;

		try {

			
			const { value: archivo } = await Swal.fire({
				title: 'Adjuntar Fotografia',
				input: 'file',
				inputAttributes:
				{
					'accept': 'image/*',
					'aria-label': 'Cargar fotografia'
				}
			})

				if (archivo) 
				{
					const fileData = new FormData();
					fileData.append('ficha',usuario.ficha);
					fileData.append('archivo',archivo);

					request.post('/CargarFotografia',fileData)
					.then(function(response)
					{
						if(response.status === 200)
						{
							if(response.data)
							{
								Swal.fire({
									position: 'center-end',
									icon: 'success',
									title: 'Documento enviado',
									showConfirmButton: false,
									timer: 2500
								});
								usuario.check23 = 2;
								temp.setState({  usuario : usuario });
							}
							else
							{
								Swal.fire({
									position: 'center-end',
									icon: 'error',
									title: 'El archivo no es válido',
									showConfirmButton: false,
									timer: 2500
								});
							}
						}
					})
					.catch(error =>
					{
						Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: 'No pudo enviarse el archivo',
							showConfirmButton: false,
							timer: 1500
						});
					});

					// const reader = new FileReader()
					// reader.onload = (e) => {
					// 	Swal.fire({
					// 		title: 'Your uploaded picture',
					// 		imageUrl: e.target.result,
					// 		imageAlt: 'The uploaded picture'
					// 	})
					// }
					// reader.readAsDataURL(file)
				}
				else
				{
					console.log(archivo);
					Swal.fire('No se selecciono ningun archivo');
				}

			

		} catch (e) {
			console.log('error:', e);
			return false;
		}
	}

	nohaylista=()=>
	{
		Swal.fire({
			toast: true,
			icon: 'warning',
			title: 'La lista de cotejo aun no se encuentra disponible',
			showConfirmButton: false,
			timer: 4000
		});
	}

	nohaycalendario=()=>
	{
		Swal.fire({
			toast: true,
			icon: 'warning',
			title: 'El calendario escolar aun no se encuentra disponible',
			showConfirmButton: false,
			timer: 4000
		});
	}

	nohaymanual=()=>
	{
		Swal.fire({
			toast: true,
			icon: 'warning',
			title: 'El manual para el curso de inducción aun no se encuentra disponible',
			showConfirmButton: false,
			timer: 4000
		});
	}

	nohayreglamento=()=>
	{
		Swal.fire({
			toast: true,
			icon: 'warning',
			title: 'El reglamento de netiqueta esta siendo revisado, estara disponible hasta mañana',
			showConfirmButton: false,
			timer: 4000
		});
	}

	contestarTest=()=>
	{
		window.open('https://docs.google.com/forms/d/e/1FAIpQLSfQoHcH1D4apsRG9gMJANzsAKWMl2222Epi_RI_Uvuq9Mod7Q/viewform?usp=sf_link', "_blank");
		this.revisarCheck(6);
	}

	solicitarSeguroSocial=()=>
	{
		window.open('https://serviciosdigitales.imss.gob.mx/', "_blank");
		this.revisarCheck(12);
	}

	actualizarDatos=()=>
	{
		window.open('http://siiesmexico.com/inscripciones/cbtis41', "_blank");
		this.revisarCheck(13);
	}

	encuestaOrientacionEducativa=()=>
	{
		window.open('https://docs.google.com/forms/d/e/1FAIpQLSfQoHcH1D4apsRG9gMJANzsAKWMl2222Epi_RI_Uvuq9Mod7Q/viewform?usp=sf_link', "_blank");
		this.revisarCheck(19);
	}

	decargarBoleta=()=>
	{
		let { usuario, generando } = this.state;
		let temp = this;

		
		if(parseInt(usuario.semestre,10) === 2)
		{
			// Swal.fire({
			// 	html: '<b><br> Las boletas de <b>SEGUNDO SEMESTRE</b> se entregaran <b>en la escuela</b> '+
			// 	' de acuerdo al calendario.<br><br>'+
			// 	'Revisa las fechas en las redes sociales, y <b>no olvides llevar '+
			// 	'los documentos que se piden.</b><br><br> </b>',
			// 	focusConfirm: false,
			// 	confirmButtonText: 'Ok'
			// });
		}
		else
		{
			request_file()
			.post(`/boleta?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
				generando = 0;
				temp.setState({  generando : generando });
			})
			.catch(error => {
				console.log(error);
			});

			generando = 1;
			this.setState({ generando : generando });
		}
	}

	decargarConstancia=()=>
	{
		let { usuario } = this.state;
		if(parseInt(usuario.check3,10) === 1)
		{
			request_file()
			.post(`/constancia?num_control=${usuario.ficha}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(error => {
				console.log(error);
			});
		}
		else
		{
			Swal.fire({
				
				icon: 'warning',
				title: 'No es posible generar tu contancia de estudios.',
				html:  	'<br><b>Comunicate al plantel.<br><br>',
				showConfirmButton: true,
				focusConfirm: true,
				confirmButtonText: 'Ok'
			});
		}
	}

	estadistica=()=>
	{
		request.get('/Reporte')
		.then(function(response)
		{
			if(response.status === 200)
			{
				Swal.fire({
					title: '<strong>Reporte de progreso</strong>',
					html: `<b><b>Listas de cotejo:</b> &nbsp; ${response.data.listadecotejo} / 1000</b> <br>` +
						  `<b><b>Referencias:</b> &nbsp; ${response.data.referencias} / 1000</b> <br>` +
						  `<b><b>Solicitudes:</b> &nbsp; ${response.data.solicitudes} / 1000</b> <br>` +
						  `<b><b>Reglamento:</b> &nbsp; ${response.data.reglamento} / 1000</b> <br>` +
						  `<b><b>Calendario:</b> &nbsp; ${response.data.calendario} / 1000</b> <br>` +
						  `<b><b>Test:</b> &nbsp; ${response.data.test} / 1000</b> <br>` +
						  `<b><b>Manual de inducción:</b> &nbsp; ${response.data.manualinduccion} / 1000</b> <br>` +
						  `<b><b>Manual de lectura:</b> &nbsp; ${response.data.manuallectura} / 1000</b> <br>` +
						  `<b><b>Manual de matematicas:</b> &nbsp; ${response.data.manualmatematicas} / 1000</b> <br>` +
						  `<b><b>Manual de ciencias:</b> &nbsp; ${response.data.manualciencias} / 1000</b> <br>` +
						  `<b><b>Estudio socio-economico:</b> &nbsp; ${response.data.estudiosocioeconomico} / 1000</b> <br>` +
						  `<b><b>Seguro social:</b> &nbsp; ${response.data.segurosocial} / 1000</b> <br>` +
						  `<b><b>Actualización de datos:</b> &nbsp; ${response.data.actualizaciondedatos} / 1000</b> <br>` +
						  `<b><b>Aviso de privacidad:</b> &nbsp; ${response.data.avisodeprivacidad} / 1000</b> <br>` +
						  `<b><b>Carta de no registro:</b> &nbsp; ${response.data.cartadenoregistro} / 1000</b>`,
				});
			}
		})
		.catch(error => { });
	}

	revisarCheck(numero)
	{
		var { usuario, progreso, barra, mensajeDeAvance, max_docs } = this.state;
		let temp = this;
		var mod=true;
		switch(numero)
		{
			case 1:  if(usuario.check1===0)  usuario.check1=1;  break;
			case 2:  if(usuario.check2===0)  usuario.check2=1;  break;
			case 3:  						 usuario.check3=1;  break;
			case 4:  if(usuario.check4===0)  usuario.check4=1;  break;
			case 5:  if(usuario.check5===0)  usuario.check5=1;  break;
			case 6:  if(usuario.check6===0)  usuario.check6=1;  break;
			case 7:  if(usuario.check7===0)  usuario.check7=1;  break;
			case 8:  if(usuario.check8===0)  usuario.check8=1;  break;
			case 9:  if(usuario.check9===0)  usuario.check9=1;  break;
			case 10: if(usuario.check10===0) usuario.check10=1; break;
			case 11: if(usuario.check11===0) usuario.check11=1; break;
			case 12: if(usuario.check12===0) usuario.check12=1; break;
			case 13: if(usuario.check13===0) usuario.check13=1; break;
			case 14: if(usuario.check14===0) usuario.check14=1; break;
			case 15: if(usuario.check15===0) usuario.check15=1; break;
			case 16: if(usuario.check16===0) usuario.check16=1; break;
			case 17: if(usuario.check17===0) usuario.check17=1; break;
			case 18: if(usuario.check18===0) usuario.check18=1; break;
			case 19: if(usuario.check19===0) usuario.check19=1; break;
			case 20: if(usuario.check20===0) usuario.check20=1; break;
			case 21: if(usuario.check21===0) usuario.check21=1; break;
			case 22: if(usuario.check22===0) usuario.check22=1; break;
			case 23: if(usuario.check23===0) usuario.check23=1; break;
			case 24: if(usuario.check24===0) usuario.check24=1; break;
			case 25: if(usuario.check25===0) usuario.check25=1; break;
		default: mod=false;
		}

		// if( usuario.check1===1 && usuario.check4===1 && usuario.check5===1 && usuario.check6===1 &&
		// 	usuario.check7===1 && usuario.check8===1 && usuario.check9===1 && usuario.check10===1 &&
		// 	usuario.check12===1 && usuario.check13===1 && usuario.check14===1 && usuario.check15===1 &&
		// 	usuario.check16===1 && usuario.check17===1 && usuario.check18===1 && usuario.check19===1 && 
		// 	usuario.check20===1 && usuario.check21===1)
		// {
		// 	mensajeDeAvance="Llena, guarda e imprime tu solicitud de inscripción para descargar la referencia bancaria";
		// }

		// if( usuario.check1===1 && usuario.check3===1 && usuario.check4===1 && usuario.check5===1 && usuario.check6===1 &&
		// 	usuario.check7===1 && usuario.check8===1 && usuario.check9===1 && usuario.check10===1 && usuario.check12===1 &&
		// 	usuario.check13===1 && usuario.check14===1 && usuario.check15===1 && usuario.check16===1 && usuario.check17===1 &&
		// 	usuario.check18===1 && usuario.check19===1 && usuario.check20===1 && usuario.check21===1)
		// {
		// 	usuario.check_ref=1;
		// 	mod=true;
		// }

		barra = usuario.check1  +  usuario.check2 +  usuario.check3 + usuario.check4 +
				usuario.check7 +  usuario.check8 + usuario.check9 + usuario.check10 +
				usuario.check12 + usuario.check14 + usuario.check15 + usuario.check19 +
				usuario.check20 + usuario.check23 + usuario.check25;

		if(usuario.check16 === 1) barra++;
		if(usuario.check17 === 1) barra++;
		if(usuario.check18 === 1) barra++;
		if(usuario.check21 === 1) barra++;
		progreso=100/max_docs*barra;

		if(mod===true)
		{
			request.post('/ActualizarRegistro',usuario)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
						usuario=response.data;
						temp.setState({ usuario : usuario, progreso : progreso, barra : barra, mensajeDeAvance : mensajeDeAvance });
					}
				}
			})
			.catch(error =>
			{
				
			});
		}
		this.setState({ usuario : usuario, progreso : progreso, barra : barra, mensajeDeAvance : mensajeDeAvance });
	}


	render(){

		//let { usuario, redireccion, progreso, barra, navmode, generando, max_docs} = this.state;
		let { usuario, redireccion, progreso, barra, navmode, mensajeDeAvance, generando, max_docs } = this.state;

		if(redireccion === 3) return <Solicitud ficha={usuario.ficha} />;
		//if(redireccion === 6) return <ResolverTest {...usuario} />;

		return (
			<div className="principal">

			{/* MENU SUPERIOR  */}
				{
					navmode.nombre === "" ? '' : <NavigationBar {...navmode} />
				}
			{/* PANTALLA PRINCIPAL DE CONTENIDO  */}
				<div id="inicio" className="inicio container-fluid padding">

					{/* BLOQUE DE INFORMACION */}
					<div className="row justify-content-around">
						<div className="animated fadeIn col-11 col-sm-10 align-self-center pt-4 pt-lg-5 pb-4 formMensaje">
							<p className="kau am textshadow lg"> Bienvenido al &nbsp;"Sistema de Ingreso 2024"&nbsp; del C.B.T.i.s.&nbsp;#41</p>
						</div>
	

						<div className="col-12 pt-3 pt-lg-4 pb-3 pb-lg-4">
						</div>

						<div className="wow animated fadeIn col-11 col-sm-10 pt-4 pt-lg-5 pb-4 pb-lg-5 formMensaje" data-wow-delay="0.2s">
							<div className="lat bl izq sm info">
								<span className="am b"> INSTRUCCIONES:<br /> <br /> </span>

								&emsp;<span className="am b"> 1) </span>&emsp; Llena tu solicitud de inscripción. <br /> <br />
								&emsp;<span className="am b"> 2) </span> &emsp; Descarga tu referencia bancaria y acude a pagarla al banco HSBC de tu elección. <br />  <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> * </span>El pago puede tardar hasta 48 en acreditarse, podrás ver un mensaje cuando esto pase. <br /><br />
								&emsp;<span className="am b"> 3) </span>&emsp; Descarga la lista de cotejo donde se indican todos los requisitos que debes llevar el día que te inscribas. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; Ve marcando los documentos que tengas, porque también debes entregarla el día de tu inscripción, reunelos para que los tengas listos. <br/> <br/>
								&emsp;<span className="am b"> 4) </span>&emsp; Imprime tu número de seguro social. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> * </span>Ingresa a la liga que se indica; En caso de no poder hacerlo debes acudir a la Clínica #32 a realizar el trámite. <br /><br />
								&emsp;<span className="am b"> 5) </span>&emsp; Descarga y llena los siguientes formatos para que los anexes a tu documentación. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> - </span>Normas de convivencia. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> - </span>Consentimiento revisión de pertenecias personales. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> - </span>Aviso de privacidad. <br />
								&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp; <span className="am b"> - </span>Carta de No registro anterior en la Dgeti. <br /><br />
								&emsp;<span className="am b"> 6) </span>&emsp; Revisa en que grupo quedaste y ve en el calendario que día debes entregar tus documentos. <br /> <br />
								&emsp;<span className="am b"> 7) </span>&emsp; Presentate en el plantel el día que te corresponde con todos tus documentos. <br /> <br /> <br />
								
								<span className="am b i">Revisa todas las ligas y descarga los documentos necesarios. </span>
								<div className="progress">
									<span className="progress-value negra cen"> <b><i> {barra} / {max_docs} </i></b> </span>
									<div className="progress-bar bg-warning" role="progressbar" style={{width: (progreso)+'%'}} aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
						</div>

						<div className="col-12 pt-3 pt-lg-4 pb-3 pb-lg-4">
						</div>
					</div>

			{/* *************************************************************************** */}
			{/* INICIO BLOQUE DE TARJETAS DE DESCARGA */}

					<div className="row justify-content-center">
						<div className="col-12 col-lg-11 col-xl-10">
							<div className="container-fluid">
								<div className="row justify-content-center">

			{/* 09 - HISTORIAL ACADEMICO */}

			{/*						<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.4s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="book" aria-hidden="true"> Historial Académico </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
												Aqui podras descargar tu hisotiral académico. 
												Si el tuyo no aparece, comunicate con nosotros.
											</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={this.descargarHistorial}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>*/}

			{/* 00 - CALIFICACION SOCIOEMOCIONAL */}

				{/*					<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="book" aria-hidden="true"> Recursos Socioemocionales </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Consulta aqui si aprobaste la materia de Recursos Socioemocionales I.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												CONSULTAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											<button className ="marco" onClick={this.consultarCalificacion}> </button>
										</div>
									</div>*/}

			{/* 00 - CORREO INSTITUCIONAL */}

									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="envelope-closed" aria-hidden="true"> Correo institucional </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui encontraras tu correo institucional, debes activarlo.
													Con el podras registrarte en tus materias que tomaras en classroom.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												CONSULTAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											<button className ="marco" onClick={this.consultarCorreo}> </button>
										</div>
									</div>

			{/* CONSTANCIA */}
								
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> </span>
													Constancia de estudios
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui puedes descargar tu constancia de estudios correspontiente al semestre
													 Agosto 2024 - Enero 2025.
												</p>
											</div>

											<div className="card-footer text-muted">
												<div>
							{/*						<button className="btn btn-success mb-2" onClick={this.agregarFotografia}>
														AGREGAR FOTO &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
													</button>
													<br/>*/}
												
													<button className="btn btn-primary" onClick={this.decargarConstancia}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
												</div>
											</div>
											
										</div>
									</div>

			{/* 04 - SOLICITUD DE INSCRIPCION */}
					
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.4s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> Solicitud de inscripción </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													{/*<span className="h5 oi b verde"> TU PAGO FUE ACREDITADO.</span> <br/><br/>*/}
													Ahora puedes llenar tu solicitud de inscripción. 
													Cuando termines imprimela y agregala a tus documentos que entregaras.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												COMPLETAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
											</div>
											{
												usuario.check3===1 ? <button className="marco ok" onClick={this.llenarSolicitud}> </button>
												: <button className="marco" onClick={this.llenarSolicitud}> </button>
											}
										</div>
									</div>


			{/* 01 - REFERENCIA DE PAGO */}
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.2s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> Referencia Bancaria </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Con esta hoja podrás acudir a tu banco HSBC mas cercano a cubrir tu cuota de cooperación voluntaria.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												 usuario.check2===1 ?
													<button className="marco acr" onClick={this.decargarReferencia}> Pago acreditado </button>
												:
													//usuario.check_ref===1 ?
														<button className="marco" onClick={this.decargarReferencia}> </button>
													//:   
														//<button className="marco notok bl md" disabled> 
														//	{ mensajeDeAvance }
														//</button>
											}
										</div>
									</div>


			{/* 02 - GRUPO ASIGNADO*/}
							
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="calendar" aria-hidden="true"> Asignación de grupo </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Felicidades!!!, fuiste asignado al grupo:<br/><br/>
													<span className="h3 b notranslate">1{usuario.grupo}{usuario.turno}</span>
													<br/><br/>
													Revisa el calendario para que veas que día debes ir a inscribirte.

												</p>
											</div>
											
										</div>
									</div>
					

			{/* 03 - CALENDARIO PARA ENTREGA DE DOCUMENTOS*/}
						
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="calendar" aria-hidden="true"> Calendario para entrega de documentos </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui puedes revisar el calendario para entrega de documentos. Reune tus documentos para que vayas
													el día y hora que te corresponde.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												MOSTRAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											<button className ="marco" onClick={this.consultar_calendario}> </button>
										</div>
									</div>
				
				
			{/* 02 - REFERENCIA DE PAGO CREDENCIAL*/}

	{/*									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
									<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.2s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> Reposición de credencial </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Si deseas tramitar una reposición de tu credencial escolar, descarga esta hoja y acude con ella
													a un banco HSBC a realizar el pago.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											<button className="marco" onClick={this.decargarReferenciaCredencial}> </button>
										</div>
									</div>*/}


			{/* 05 - LISTA DE COTEJO */}
					
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi h5" data-glyph="paperclip" aria-hidden="true"> Requisitos de inscripción </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Esta lista te servira para marcar los documentos con los que cuentas, y saber cuales te hacen falta. 
													Deberás entregarla al frente de tus documentos.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												usuario.check1===1 ? <button className ="marco ok" onClick={(e)=>this.descargar(e,1)}> </button>
												: <button className ="marco" onClick={(e)=>this.descargar(e,1)}> </button>
												//<button className ="marco" onClick={this.nohaylista}> </button>
											}
										</div>
									</div>
				

			{/* 06 - SEGURO SOCIAL */}
						
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="pencil" aria-hidden="true"> Seguro Social </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Ingresa en esta liga y realiza tu trámite para la solicitud de tu número de seguro social.
													En caso de no poder realizarlo deberás acudir a la Clínica #32 a realizar el trámite.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												COMPLETAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
											</div>
											{
												usuario.check12===1 ? <button className="marco ok" onClick={this.solicitarSeguroSocial}></button>
												: <button className="marco" onClick={this.solicitarSeguroSocial}></button>
											}
										</div>
									</div>
						
			
			{/* 07 - REGLAMENTO ESCOLAR */}
							{
								//usuario.check3===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="file" aria-hidden="true"> Normas De Convivencia</span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Descarga y lee las normas de convivencia escolar. 
													Debes imprimir, llenar y entregar la última hoja junto con tus documentos.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												usuario.check4===1 ? <button className="marco ok" onClick={(e)=>this.descargar(e,4)}> </button>
												: <button className="marco" onClick={(e)=>this.descargar(e,4)}> </button>
											}
										</div>
									</div>
								//: ""
							}


			{/* 08 - CONSENTIMIENTO REVISION DE PERTENECIAS PERSONALES */}
							{
								//usuario.check3===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="file" aria-hidden="true"> Consentimiento Para Revisión De Pertenecias Personales</span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Descarga esta Carta Autorizacion para que sea firmada. Esto nos ayudara a prevenir delitos dentro del plantel
													implementando estrategias de seguridad.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												usuario.check25===1 ? <button className="marco ok" onClick={(e)=>this.descargar(e,25)}> </button>
												: <button className="marco" onClick={(e)=>this.descargar(e,25)}> </button>
											}
										</div>
									</div>
								//: ""
							}


				{/* 09 - ENCUESTA DE CONDUCTA */}
							
{/*									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="pencil" aria-hidden="true"> Encuesta </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Pide a tus padres que contesten la siguiente encuesta.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												COMPLETAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
											</div>
											{
												usuario.check19===1 ? <button className="marco ok" onClick={this.encuestaOrientacionEducativa}></button>
												: <button className="marco" onClick={this.encuestaOrientacionEducativa}></button>
											}
										</div>
									</div>*/}


			{/* 10 - AVISO DE PRIVACIDAD */}
							{
								//usuario.check3===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.2s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> Aviso de privacidad </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Formato de aviso de privacidad para el uso de datos personales. Debera ser firmado por tu padre o tutor,
													y entregado con tus documentos.
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												usuario.check14===1 ? <button className="marco ok" onClick={(e)=>this.descargar(e,14)}></button>
												: <button className="marco" onClick={(e)=>this.descargar(e,14)}></button>
											}
										</div>
									</div>
								//: ""
							}


			{/* 11 - NO REGISTRO EN DGETI */}
							{
								//usuario.check3===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="wow animated bounceIn card text-center sombra" data-wow-delay="0.4s">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> Carta de No Registro en la Dgeti </span>
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Este documento sirve para confirmar que no has estado inscrito(a) en nuestro subsistema, además de que estás
													de acuerdo con tu grupo y turno. 
												</p>
											</div>
											<div className="card-footer text-muted am b">
												DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
											</div>
											{
												usuario.check15===1 ? <button className="marco ok" onClick={(e)=>this.descargar(e,15)}></button>
												: <button className="marco" onClick={(e)=>this.descargar(e,15)}></button>
											}
										</div>
									</div>
								//: ""
							}


			{/* FIN BLOQUE DE TARJETAS DE DESCARGA */}
								</div>
							</div>
						</div>
					</div>
			{/* *************************************************************************** */}

			{/* MENSAJE INFERIOR */}
		{/*			<div className="wow animated fadeIn row pt-3 pt-md-4 pt-lg-5 justify-content-center"  data-wow-delay="0.6s">
						<div className="col-11 col-sm-10">
							<div className="alert alert-warning sm" role="alert">
									Si no realizas tu proceso en linea, o no te presentas el día que te corresponde, <b>pierdes tu lugar.</b><br/>
									No Faltes!!!
							</div>
						</div>
					</div>*/}

				</div> {/* Cierre de bloque principal*/}

			{/* PIE DE PAGINA */}
				<PieDePagina />

			</div>

		);
	}

}

export default Descargas;